import { createReducer } from "@reduxjs/toolkit";
import {
  checkUser,
  forgetPass,
  getuserTickets,
  logOut,
  sendCode,
  userLogin,
  userRegister,
} from "./actions";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const initialState = {
  user: {
    id: null,
    name: "",
    lname: "",
    email: "",
    mobileno: "",
    avatar: null,
    email_verified_at: null,
    type: 2,
    company_id: null,
    created_at: "",
    updated_at: "",
    provider: null,
    provider_id: null,
    event_id: "",
    booth_id: null,
    sponsor_id: null,
    device_token: null,
    ticket: null,
  },
  ticket: "",
  qrcode: "",
  paymentOrder: "",
  code: "",
  isAuth: false,
  enteredEmail: "",
  userTicket: [],
};

export const userReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(userRegister.pending, (state) => {
      // state.errorpayload = true;
    })
    .addCase(userRegister.fulfilled, (state, { payload }) => {
      state.user = payload;
      //state.errorpayload = false;
      state.isAuth = true;
    })
    .addCase(getuserTickets.fulfilled, (state, { payload }) => {
      console.log(payload, "useruser");
      state.userTicket = payload.tickets;
    })
    .addCase(sendCode.fulfilled, (state, { payload }) => {
      state.code = payload;
    })
    .addCase(userRegister.rejected, (state, { payload }) => {
      for (var key in payload?.errors) {
        toast.error(payload?.errors[key][0]);
      }
    })
    .addCase(userLogin.pending, (state) => {
      // state.errorpayload = true;
    })
    .addCase(userLogin.fulfilled, (state, { payload }) => {
      state.user = payload;
      state.isAuth = true;
    })
    .addCase(userLogin.rejected, (state, { payload }) => {
      console.log(payload, "payload");
      for (var key in payload?.errors) {
        toast.error(payload?.errors[key][0]);
      }
    })
    .addCase(forgetPass.fulfilled, (state, { payload }) => {
      state.enteredEmail = payload;
    })
    .addCase(checkUser.fulfilled, (state, { payload }) => {
      console.log(payload[0], payload[1], "pay");
      state.user = payload[0];
      state.isAuth = payload[1];
    })
    .addCase(logOut.fulfilled, (state, { payload }) => {
      state.user = null;
      state.isAuth = false;
      window.location.reload();
    });
});

export default userReducer;
