import React from "react";
import Navbar from "../../components/Navbar";

function SessionSpeakers() {
  return (
    <div>
      <Navbar></Navbar>
      <main>
        <div class="header-section">
          <img
            src="assets/images/hero-slider.jpg"
            class="d-block w-100 head-slide-img"
          />
          <div class="half-circle">
            <img src="assets/images/semi-circle.png" class="semi-circle" />
            <img
              src="assets/images/teemu-paananen-unsplash.png"
              class="circle-logo"
            />
            <div class="overlay-info overlay-info2">
              <p class="light-blue-text regular-font mb-1 page-title3">
                Day One
              </p>
              <p class="white-text bold-font page-title3 mb-1">
                How to manage your time
              </p>
              <div class="w-25 white-text text-center mx-auto mb-4">
                <div class="rate">
                  <div class="rating">
                    <input type="radio" name="rating" value="5" id="5" />
                    <label for="5">☆</label>
                    <input
                      type="radio"
                      name="rating"
                      value="4"
                      id="4"
                      checked
                    />
                    <label for="4">☆</label>
                    <input type="radio" name="rating" value="3" id="3" />
                    <label for="3">☆</label>
                    <input type="radio" name="rating" value="2" id="2" />
                    <label for="2">☆</label>
                    <input type="radio" name="rating" value="1" id="1" />
                    <label for="1">☆</label>
                  </div>
                </div>
              </div>
              <p class="white-text regular-font page-info2">
                Aenean sed lorem est. Sed quis neque ut nibh suscipit imperdiet
                ac non augue. Aenean ornare sit amet lectus non tristique. Nunc
                ut volutpat lectus. Nulla velit augue, pulvinar sed nisi sit
                amet, eleifend fermentum est. Quisque nibh justo, congue ut erat
                at
              </p>
              <p class="white-text regular-font pt-3">
                <i class="fa-solid fa-clock me-2"></i> 08:00 AM - 10:00 AM
              </p>
            </div>
          </div>
        </div>
        <div class="section-top-space">
          <div class="container">
            <div class="row">
              <div class="col-md-10 offset-md-1 text-center">
                <div class="video-section">
                  <video
                    class="page-video"
                    id="videoPage"
                    autoplay="autoplay"
                    poster="assets/images/video-poster.png"
                    onclick="this.play();"
                  >
                    <source
                      src="assets/videos/Pilvery-Zoomedout.mp4"
                      type="video/mp4"
                    />
                  </video>
                  <img
                    src="assets/images/play-filled.png"
                    class="play-btn"
                    onclick="videoPage.play(); this.style.visibility ='hidden'"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="section-yaxis-space" id="SpeakersSection">
          <div class="container">
            <div class="row mb-4">
              <div class="col-md-12">
                <p class="dark-blue-text bold-font page-title1 lined-title3">
                  List of Speakers
                </p>
              </div>
            </div>
          </div>
          <div class="cards-brands-slider no-margin-slider">
            <div class="item">
              <div class="card page-card up-view">
                <div class="card-body">
                  <img
                    src="assets/images/user1.jpg"
                    class="speaker-image"
                    alt="Development Icon"
                  />
                  <div class="card-div">
                    <p class="card-title regular-font page-title3 mb-0">
                      John Doe
                    </p>
                    <p class="card-info bold-font mb-0 page-title4">
                      PR Manager
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="item">
              <div class="card page-card up-view">
                <div class="card-body">
                  <img
                    src="assets/images/user2.jpg"
                    class="speaker-image"
                    alt="Development Icon"
                  />
                  <div class="card-div">
                    <p class="card-title regular-font page-title3 mb-0">
                      John Doe
                    </p>
                    <p class="card-info bold-font mb-0 page-title4">
                      PR Manager
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="item">
              <div class="card page-card up-view">
                <div class="card-body">
                  <img
                    src="assets/images/user3.jpg"
                    class="speaker-image"
                    alt="Development Icon"
                  />
                  <div class="card-div">
                    <p class="card-title regular-font page-title3 mb-0">
                      John Doe
                    </p>
                    <p class="card-info bold-font mb-0 page-title4">
                      PR Manager
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="item">
              <div class="card page-card up-view">
                <div class="card-body">
                  <img
                    src="assets/images/user4.jpg"
                    class="speaker-image"
                    alt="Development Icon"
                  />
                  <div class="card-div">
                    <p class="card-title regular-font page-title3 mb-0">
                      John Doe
                    </p>
                    <p class="card-info bold-font mb-0 page-title4">
                      PR Manager
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="item">
              <div class="card page-card up-view">
                <div class="card-body">
                  <img
                    src="assets/images/user1.jpg"
                    class="speaker-image"
                    alt="Development Icon"
                  />
                  <div class="card-div">
                    <p class="card-title regular-font page-title3 mb-0">
                      John Doe
                    </p>
                    <p class="card-info bold-font mb-0 page-title4">
                      PR Manager
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="item">
              <div class="card page-card up-view">
                <div class="card-body">
                  <img
                    src="assets/images/user2.jpg"
                    class="speaker-image"
                    alt="Development Icon"
                  />
                  <div class="card-div">
                    <p class="card-title regular-font page-title3 mb-0">
                      John Doe
                    </p>
                    <p class="card-info bold-font mb-0 page-title4">
                      PR Manager
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="item">
              <div class="card page-card up-view">
                <div class="card-body">
                  <img
                    src="assets/images/user3.jpg"
                    class="speaker-image"
                    alt="Development Icon"
                  />
                  <div class="card-div">
                    <p class="card-title regular-font page-title3 mb-0">
                      John Doe
                    </p>
                    <p class="card-info bold-font mb-0 page-title4">
                      PR Manager
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="section-yaxis-space attendees-section">
          <div class="container">
            <div class="row mb-4">
              <div class="col-md-12 text-center">
                <p class="light-blue-text bold-font page-title1 lined-title4">
                  List of Attendees
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-10 offset-lg-1 col-md-10 offset-md-1 text-center">
                <div class="list-section">
                  <div class="list-container">
                    <ul class="page-list">
                      <li>
                        <div class="d-flex justify-content-between mb-2">
                          <p class="regular-font white-text page-info3 mb-0">
                            <img
                              src="assets/images/user1.jpg"
                              class="user-img me-3"
                            />{" "}
                            Nicolas Sanders
                          </p>
                          <img
                            src="assets/images/comments-o.svg"
                            class="side-icon"
                          />
                        </div>
                      </li>
                      <li>
                        <div class="d-flex justify-content-between mb-2">
                          <p class="regular-font white-text page-info3 mb-0">
                            <img
                              src="assets/images/user3.jpg"
                              class="user-img me-3"
                            />{" "}
                            Sara Cooper
                          </p>
                          <img
                            src="assets/images/comments-o.svg"
                            class="side-icon"
                          />
                        </div>
                      </li>
                      <li>
                        <div class="d-flex justify-content-between mb-2">
                          <p class="regular-font white-text page-info3 mb-0">
                            <img
                              src="assets/images/user1.jpg"
                              class="user-img me-3"
                            />{" "}
                            Nicolas Sanders
                          </p>
                          <img
                            src="assets/images/comments-o.svg"
                            class="side-icon"
                          />
                        </div>
                      </li>
                      <li>
                        <div class="d-flex justify-content-between mb-2">
                          <p class="regular-font white-text page-info3">
                            <img
                              src="assets/images/user3.jpg"
                              class="user-img me-3"
                            />{" "}
                            Sara Cooper
                          </p>
                          <img
                            src="assets/images/comments-o.svg"
                            class="side-icon"
                          />
                        </div>
                      </li>
                      <li>
                        <div class="d-flex justify-content-between mb-2">
                          <p class="regular-font white-text page-info3 mb-0">
                            <img
                              src="assets/images/user1.jpg"
                              class="user-img me-3"
                            />{" "}
                            Nicolas Sanders
                          </p>
                          <img
                            src="assets/images/comments-o.svg"
                            class="side-icon"
                          />
                        </div>
                      </li>
                      <li>
                        <div class="d-flex justify-content-between mb-2">
                          <p class="regular-font white-text page-info3">
                            <img
                              src="assets/images/user3.jpg"
                              class="user-img me-3"
                            />{" "}
                            Sara Cooper
                          </p>
                          <img
                            src="assets/images/comments-o.svg"
                            class="side-icon"
                          />
                        </div>
                      </li>
                      <li>
                        <div class="d-flex justify-content-between mb-2">
                          <p class="regular-font white-text page-info3 mb-0">
                            <img
                              src="assets/images/user1.jpg"
                              class="user-img me-3"
                            />{" "}
                            Nicolas Sanders
                          </p>
                          <img
                            src="assets/images/comments-o.svg"
                            class="side-icon"
                          />
                        </div>
                      </li>
                      <li>
                        <div class="d-flex justify-content-between mb-2">
                          <p class="regular-font white-text page-info3">
                            <img
                              src="assets/images/user3.jpg"
                              class="user-img me-3"
                            />{" "}
                            Sara Cooper
                          </p>
                          <img
                            src="assets/images/comments-o.svg"
                            class="side-icon"
                          />
                        </div>
                      </li>
                      <li>
                        <div class="d-flex justify-content-between mb-2">
                          <p class="regular-font white-text page-info3 mb-0">
                            <img
                              src="assets/images/user1.jpg"
                              class="user-img me-3"
                            />{" "}
                            Nicolas Sanders
                          </p>
                          <img
                            src="assets/images/comments-o.svg"
                            class="side-icon"
                          />
                        </div>
                      </li>
                      <li>
                        <div class="d-flex justify-content-between mb-2">
                          <p class="regular-font white-text page-info3">
                            <img
                              src="assets/images/user3.jpg"
                              class="user-img me-3"
                            />{" "}
                            Sara Cooper
                          </p>
                          <img
                            src="assets/images/comments-o.svg"
                            class="side-icon"
                          />
                        </div>
                      </li>
                      <li>
                        <div class="d-flex justify-content-between mb-2">
                          <p class="regular-font white-text page-info3 mb-0">
                            <img
                              src="assets/images/user1.jpg"
                              class="user-img me-3"
                            />{" "}
                            Nicolas Sanders
                          </p>
                          <img
                            src="assets/images/comments-o.svg"
                            class="side-icon"
                          />
                        </div>
                      </li>
                      <li>
                        <div class="d-flex justify-content-between mb-2">
                          <p class="regular-font white-text page-info3 mb-0">
                            <img
                              src="assets/images/user3.jpg"
                              class="user-img me-3"
                            />{" "}
                            Sara Cooper
                          </p>
                          <img
                            src="assets/images/comments-o.svg"
                            class="side-icon"
                          />
                        </div>
                      </li>
                      <li>
                        <div class="d-flex justify-content-between mb-2">
                          <p class="regular-font white-text page-info3 mb-0">
                            <img
                              src="assets/images/user1.jpg"
                              class="user-img me-3"
                            />{" "}
                            Nicolas Sanders
                          </p>
                          <img
                            src="assets/images/comments-o.svg"
                            class="side-icon"
                          />
                        </div>
                      </li>
                      <li>
                        <div class="d-flex justify-content-between mb-2">
                          <p class="regular-font white-text page-info3">
                            <img
                              src="assets/images/user3.jpg"
                              class="user-img me-3"
                            />{" "}
                            Sara Cooper
                          </p>
                          <img
                            src="assets/images/comments-o.svg"
                            class="side-icon"
                          />
                        </div>
                      </li>
                      <li>
                        <div class="d-flex justify-content-between mb-2">
                          <p class="regular-font white-text page-info3 mb-0">
                            <img
                              src="assets/images/user1.jpg"
                              class="user-img me-3"
                            />{" "}
                            Nicolas Sanders
                          </p>
                          <img
                            src="assets/images/comments-o.svg"
                            class="side-icon"
                          />
                        </div>
                      </li>
                      <li>
                        <div class="d-flex justify-content-between mb-2">
                          <p class="regular-font white-text page-info3">
                            <img
                              src="assets/images/user3.jpg"
                              class="user-img me-3"
                            />{" "}
                            Sara Cooper
                          </p>
                          <img
                            src="assets/images/comments-o.svg"
                            class="side-icon"
                          />
                        </div>
                      </li>
                      <li>
                        <div class="d-flex justify-content-between mb-2">
                          <p class="regular-font white-text page-info3 mb-0">
                            <img
                              src="assets/images/user1.jpg"
                              class="user-img me-3"
                            />{" "}
                            Nicolas Sanders
                          </p>
                          <img
                            src="assets/images/comments-o.svg"
                            class="side-icon"
                          />
                        </div>
                      </li>
                      <li>
                        <div class="d-flex justify-content-between mb-2">
                          <p class="regular-font white-text page-info3">
                            <img
                              src="assets/images/user3.jpg"
                              class="user-img me-3"
                            />{" "}
                            Sara Cooper
                          </p>
                          <img
                            src="assets/images/comments-o.svg"
                            class="side-icon"
                          />
                        </div>
                      </li>
                      <li>
                        <div class="d-flex justify-content-between mb-2">
                          <p class="regular-font white-text page-info3 mb-0">
                            <img
                              src="assets/images/user1.jpg"
                              class="user-img me-3"
                            />{" "}
                            Nicolas Sanders
                          </p>
                          <img
                            src="assets/images/comments-o.svg"
                            class="side-icon"
                          />
                        </div>
                      </li>
                      <li>
                        <div class="d-flex justify-content-between mb-2">
                          <p class="regular-font white-text page-info3">
                            <img
                              src="assets/images/user3.jpg"
                              class="user-img me-3"
                            />{" "}
                            Sara Cooper
                          </p>
                          <img
                            src="assets/images/comments-o.svg"
                            class="side-icon"
                          />
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      <footer>
        <div class="footer-section">
          <div class="container">
            <div class="row mb-4">
              <div class="col-md-12 text-center">
                <a href="index.html">
                  <img
                    src="assets/images/frontiers-logo.png"
                    class="frontiers-logo"
                    alt="Frontiers Logo"
                  />
                </a>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4 offset-md-4 text-center">
                <a href="" class="me-5 dark-text">
                  <i class="fa-brands fa-facebook-square page-info1"></i>
                </a>
                <a href="" class="me-5 dark-text">
                  <i class="fa-brands fa-instagram page-info1"></i>
                </a>
                <a href="" class="me-5 dark-text">
                  <i class="fa-brands fa-twitter page-info1"></i>
                </a>
                <a href="" class="dark-text">
                  <i class="fa-brands fa-linkedin page-info1"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="footer-line2">
          <div class="container">
            <div class="row">
              <div class="col-md-12 text-center">
                <p class="mb-0 white-text">
                  © Frontiers. All rights reserved 2022 - Made By O-Projects
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default SessionSpeakers;
