import { createReducer } from "@reduxjs/toolkit";
import {
  getBoothbyID,
  getBooths,
  getBoothslots,
  getBoothTeam,
  getMybooths,
} from "./actions";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const initialState = {
  booths: [
    {
      id: 129,
      name: "New Booth",
      type: 1,
      about: "orange company",
      address: "cairo",
      logo: null,
      image: null,
      video: "videourl",
      website: "websiet",
      facebook: "facebook",
      instagram: null,
      youtube: "youtube",
      event_id: 18,
      user_id: null,
      created_at: "2022-08-14T15:04:41.000000Z",
      updated_at: "2022-08-14T15:04:41.000000Z",
      external_link: null,
      external_lable: "External Label",
      gallery_images: [],
      gallery_videos: [],
    },
  ],
  booth: {},
  team: [],
  slots: [],
  userBooths: [],
  loading: false,
};

export const boothsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getBooths.pending, (state) => {
      // state.errorpayload = true;
    })
    .addCase(getBooths.fulfilled, (state, { payload }) => {
      state.booths = payload.list;
    })
    .addCase(getBooths.rejected, (state, { payload }) => {
      console.log("hi");
      //state.errorpayload = false;
    })
    .addCase(getBoothbyID.pending, (state) => {
      // state.errorpayload = true;
    })
    .addCase(getBoothbyID.fulfilled, (state, { payload }) => {
      state.booth = payload.record;
      state.loading = true;
    })
    .addCase(getBoothTeam.fulfilled, (state, { payload }) => {
      state.team = payload.list;
      state.loading = true;
    })
    .addCase(getBoothTeam.pending, (state, { payload }) => {
      state.loading = false;
    })
    .addCase(getBoothslots.fulfilled, (state, { payload }) => {
      console.log("hi");
      state.slots = payload;
      state.loading = false;

      //state.errorpayload = false;
    })
    .addCase(getMybooths.fulfilled, (state, { payload }) => {
      console.log("hi");
      state.userBooths = payload;
      state.loading = false;

      //state.errorpayload = false;
    })
    .addCase(getBoothslots.pending, (state, { payload }) => {
      state.loading = true;

      //state.errorpayload = false;
    })
    .addCase(getBoothbyID.rejected, (state, { payload }) => {
      console.log("hi");
      //state.errorpayload = false;
    });
});

export default boothsReducer;
