import React, { useEffect, useState } from "react";
import group31 from "../../assets/images/Group931.jpg";
import { useSelector, useDispatch } from "react-redux";
import { getCountries, purchaseTicket } from "../../api/Tickets/actions";
import { useNavigate, useParams } from "react-router-dom";
import countryList from "./data";
const AccountInfo = {
  name: "",
  lname: "",
  mobileno: "",
  email: "",
  reason: "1",
  guests: [],
  tickets: [],
  subscribe: false,
  event_id: null,
  passcode: "",
  ticketid: null,
  walletnumber: null,
  quantity: null,
  areaofbuss: "",
  job: "",
  dreamjob: "",
  payment_method: "online",
  answers: [],
};

function TicketForm() {
  const { selectedTicket, error, paymentinfo } = useSelector(
    (state) => state.ticket
  );
  const navigate = useNavigate();
  const { record } = useSelector((state) => state.event);
  const [lang, setLang] = useState("en");
  const { user } = useSelector((state) => state.user);
  const [userInfo, setUserInfo] = useState(AccountInfo);
  const [empty, setEmpty] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [order, setOrder] = useState([]);
  const dispatch = useDispatch();

  const router = useParams();
  console.log(selectedTicket, "selected");
  console.log(userInfo, "userInfo");
  const ChangeGuest = (e, index) => {
    let guest = [...userInfo.guests];
    guest[index] = { ...guest[index], [e.target.name]: e.target.value };
    setUserInfo({ ...userInfo, guests: guest });
  };
  const handleguestanswer = (e, quesId, type, guestindex) => {
    let answers = [...userInfo.guests[guestindex].answers];
    let index = record.questions.find((element) => element.id == quesId);
    let obj = answers.find((o, i) => {
      if (o.questionid === quesId) {
        if (type == "session") {
          if (answers[i].answer.indexOf(e.target.value) == -1) {
            if (answers[i].answer.length == index.maxchoice) {
              document.getElementById(
                `checkguest${guestindex}${e.target.value}`
              ).checked = false;
              return;
            }
            answers[i].answer.push(e.target.value);
          } else {
            answers[i].answer.splice(
              answers[i].answer.indexOf(e.target.value),
              1
            );
          }
        }
        if (type == "checkbox" || type == "session") {
          if (answers[i].answer.indexOf(e.target.value) == -1) {
            if (answers[i].answer.length == index.maxchoice) {
              document.getElementById(
                `checkguest${guestindex}${e.target.value}`
              ).checked = false;
              return;
            }
            answers[i].answer.push(e.target.value);
          } else {
            answers[i].answer.splice(
              answers[i].answer.indexOf(e.target.value),
              1
            );
          }
        } else if (type == "image") {
          answers[i] = {
            questionid: quesId,
            answer: e,
          };
        } else {
          answers[i] = { questionid: quesId, answer: e.target.value };
        }
        return true; // stop searching
      }
    });
    let gues = [...userInfo.guests];
    gues[guestindex].answers = answers;
    setUserInfo({ ...userInfo, guests: gues });
  };

  const handleanswer = (e, quesId, type) => {
    let answers = [...userInfo.answers];
    let index = record?.questions.find((element) => element.id == quesId);
    console.log(answers);
    let obj = answers.find((o, i) => {
      if (o.questionid === quesId) {
        if (type == "checkbox" || type == "session") {
          if (answers[i].answer.indexOf(e.target.value) == -1) {
            if (answers[i].answer.length == index.maxchoice) {
              document.getElementById(`check${e.target.value}`).checked = false;
              return;
            }
            answers[i].answer.push(e.target.value);
          } else if (answers[i].answer.indexOf(e.target.value) != -1) {
            answers[i].answer.splice(
              answers[i].answer.indexOf(e.target.value),
              1
            );
          }
        } else if (type == "image") {
          console.log(e, "e");

          answers[i] = {
            questionid: quesId,
            answer: e,
          };
        } else {
          console.log(e, "e1");

          answers[i] = { questionid: quesId, answer: e.target.value };
        }
        return true; // stop searching
      }
    });
    setUserInfo({ ...userInfo, answers: answers });
  };
  function imageToBase64(file) {
    return new Promise((resolve, reject) => {
      if (!file) {
        return;
      }
      const reader = new FileReader();
      reader.onload = () => {
        const base64String = reader.result;
        resolve(base64String);
      };
      reader.onerror = () => {
        reject(new Error("Error reading the file"));
      };
      reader.readAsDataURL(file); // Read the file as a data URL
    });
  }
  const handletick = (e, index, main) => {
    let tickuser = { ...userInfo };
    let ord = [...order];
    if (main) {
      if (userInfo.ticketid != e.target.value) {
        tickuser.tickets[0].ticketid = order[e.target.value].id;
        tickuser.ticketid = order[e.target.value].id;
        const oid = order.findIndex(
          (element) => element.id === order[e.target.value].id
        );
        ord[oid].Taken = ord[oid].Taken + 1;
        const index = order.findIndex(
          (element) => element.id === userInfo.ticketid
        );
        if (index !== -1) {
          ord[index].Taken = ord[index].Taken - 1;
        }
      }
    } else {
      const oid = ord.findIndex(
        (element) => element.id === ord[e.target.value].id
      );
      ord[oid].Taken = ord[oid].Taken + 1;
      const tech = order.findIndex(
        (element) => element.id === userInfo.tickets[index + 1].ticketid
      );
      console.log(tech, userInfo.tickets, "user2");
      if (tech !== -1) {
        ord[tech].Taken = ord[tech].Taken - 1;
      }
      tickuser.tickets[index + 1].ticketid = ord[e.target.value].id;
      tickuser.guests[index].guest_ticketid = ord[e.target.value].id;
    }

    console.log(tickuser, userInfo.tickets, "user2");

    setOrder(ord);
    setUserInfo(tickuser);
  };
  useEffect(() => {
    // if(!selectedTicket.ticketid)
    // {
    //   navigate(`/${router.id}/purchase-ticket`);
    // }
    let passcode = "";
    console.log(
      paymentinfo,
      selectedTicket.length,
      record,
      user,
      "paymentinfo"
    );
    if (selectedTicket.length > 0 && record && user) {
      console.log(
        paymentinfo.length,
        selectedTicket,
        record,
        user,
        "paymentinfo"
      );

      if (selectedTicket[0].passcode) {
        passcode = selectedTicket[0].passcode;
      }
      let guest = [];
      let answers = [];
      let tick = [];
      let orders = [];
      for (let i = 0; i < paymentinfo.length; i++) {
        if (paymentinfo.length == 1) {
          tick.push({
            id: paymentinfo[i].ticketid,
            name: paymentinfo[i].name,
            quantity: paymentinfo[i].quantity,
            Taken: 0,
          });
          for (let j = 0; j < paymentinfo[i].quantity; j++) {
            orders.push({ ticketid: paymentinfo[i].ticketid, quantity: 1 });
          }
        } else {
          tick.push({
            id: paymentinfo[i].ticketid,
            name: paymentinfo[i].name,
            quantity: paymentinfo[i].quantity,
            Taken: 0,
          });
          for (let j = 0; j < paymentinfo[i].quantity; j++) {
            orders.push({ ticketid: 0, quantity: 1 });
          }
        }
      }
      setOrder(tick);
      if (orders.length > 1) {
        for (let i = 1; i < orders.length; i++) {
          if (record.require_questions == 1) {
            let ans = [];
            for (let i = 0; i < record.questions.length; i++) {
              ans.push({
                questionid: record.questions[i].id,
                answer:
                  record.questions[i].type == "checkbox" ||
                  record.questions[i].type == "session"
                    ? []
                    : "",
              });
            }
            guest.push({
              name: "",
              email: "",
              mobileno: "",
              answers: ans,
              guest_ticketid: null,
            });
          } else {
            guest.push({
              name: "",
              email: "",
              mobileno: "",
              guest_ticketid: null,
            });
          }
        }
      }
      console.log(record.questions, "ques");
      if (record?.questions?.length > 0) {
        for (let i = 0; i < record.questions.length; i++) {
          answers.push({
            questionid: record.questions[i].id,
            answer:
              record.questions[i].type == "checkbox" ||
              record.questions[i].type == "session"
                ? []
                : "",
          });
        }
      }
      console.log(passcode, "user3");
      setUserInfo({
        ...userInfo,
        event_id: record.id,
        ticketid: paymentinfo.length == 1 ? paymentinfo[0].ticketid : null,
        quantity: orders.length,
        passcode: passcode,
        guests: guest,
        name: user?.name,
        lname: user?.lname,
        email: user?.email,
        mobileno: user?.mobileno,
        tickets: orders,
        answers: answers,
      });
    }
  }, [record, selectedTicket, paymentinfo, user]);

  console.log(paymentinfo.length, "info");
  console.log(order, "info");
  const PurchaseTicket = () => {
    let text = `${window?.location?.href}`;
    userInfo.template_url = text.substr(0, text.lastIndexOf("/"));
    //userInfo.template_url = "https://demo.frontiers.events/ICCS1";
    for (let i = 0; i < userInfo.answers.length; i++) {
      if (
        (userInfo.answers[i].answer == "" &&
          record.questions[i].mandatory == 1) ||
        !userInfo.payment_method ||
        (userInfo.payment_method == "wallet" && !userInfo.walletnumber)
      ) {
        setEmpty(true);
        return;
      }
    }
    if (userInfo.quantity > 1 && pageNumber == 1) {
      setPageNumber(2);
      window.scrollTo(0, 0);
    } else {
      for (let i = 0; i < userInfo.guests.length; i++) {
        if (
          userInfo.guests[i].name == "" ||
          userInfo.guests[i].email == "" ||
          userInfo.guests[i].mobileno == ""
        ) {
          setEmpty(true);
          return;
        }
      }
      if (record.require_questions) {
        for (let i = 0; i < userInfo.guests; i++) {
          for (let j = 0; j < userInfo.guests[i].answers[j].length; j++) {
            if (
              userInfo.guests[i].answers[j].answer == "" &&
              record.questions[i].mandatory == 1
            ) {
              setEmpty(true);
              return;
            }
          }
        }
      }
      dispatch(purchaseTicket(userInfo));
    }
  };
  return (
    <div>
      <main>
        <div class="container-fluid">
          <div class="row align-items-center">
            <div class="col-md-6 p-0">
              <div class="position-relative">
                <img src={group31} class="side-img" />
                <div class="side-vertical-tabs">
                  <p class="side-menu-title text-center text-md-end pe-4">
                    {pageNumber == 1 ? "Reservation" : "Guest Information"}
                  </p>
                </div>
              </div>
            </div>
            {pageNumber == 1 ? (
              <div class="col-md-6 half-side-page">
                <div class="px-5">
                  <p class="page-title5 blue-text bold-font mb-5">
                    Account Information
                  </p>

                  <div class="accordion-item ticket-info-card">
                    {/* <div
                      class="ticket-type-head d-flex justify-content-between bold-font"
                      data-bs-toggle="collapse"
                      data-bs-target="#ticketHead"
                    >
                      <p class=" bold-font page-info1 mb-0">
                        {selectedTicket?.name}
                      </p>
                      <p class="bold-font page-info1 price-text mb-0">
                        {selectedTicket?.price}
                      </p>
                    </div> */}
                    {/* <div
                      id="ticketHead"
                      class="accordion-collapse collapse show ticket-body"
                    >
                      <div class="accordion-body">
                        <p class="regular-font">
                          {selectedTicket?.description}
                        </p>
                      </div>
                    </div> */}
                  </div>

                  <div class="page-form mt-4">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="mb-3">
                          <label for="fname" class="form-label">
                            First Name
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            id="fname"
                            placeholder=""
                            value={user?.name}
                            disabled
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="mb-3">
                          <label for="lname" class="form-label">
                            Last Name
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            id="lname"
                            placeholder=""
                            value={user?.lname}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="mb-3">
                          <label for="mail" class="form-label">
                            Email Address
                          </label>
                          <input
                            type="email"
                            class="form-control"
                            id="mail"
                            placeholder=""
                            value={user?.email}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="mb-3">
                          <label for="phone" class="form-label">
                            Phone Number
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            id="phone"
                            placeholder=""
                            value={user?.mobileno}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    {paymentinfo.length > 1 && (
                      <div
                        className="row"
                        style={{ width: "100%", marginBottom: "1rem" }}
                      >
                        <div className="col-md-12">
                          <label>
                            {lang == "en" ? "Ticket Type" : "نوع التذكرة"}
                          </label>
                          <select
                            style={
                              (!userInfo?.tickets[0]?.ticketid ||
                                !userInfo?.ticketid) &&
                              error
                                ? { border: "1px solid red " }
                                : {}
                            }
                            onChange={(e) => handletick(e, 0, true)}
                            className="form-control nav-selection-bar pr-3"
                          >
                            <option value={""}></option>

                            {order.map((ord, index) => (
                              <React.Fragment key={index}>
                                <option
                                  value={index}
                                  selected={
                                    userInfo?.tickets[0]?.ticketid == ord.id
                                      ? true
                                      : false
                                  }
                                  disabled={
                                    ord.Taken != ord.quantity ? false : true
                                  }
                                >
                                  {ord.name}
                                </option>
                              </React.Fragment>
                            ))}
                          </select>
                        </div>
                      </div>
                    )}
                    <div
                      className="row"
                      style={{ width: "100%", marginBottom: "1rem" }}
                    >
                      {record?.questions?.map((ques, index) => (
                        <React.Fragment key={index}>
                          {ques.type == "radiobutton" ? (
                            <div
                              className="col-md-6 "
                              style={{ marginBottom: "1rem" }}
                            >
                              <p className="form-label">{ques.name}</p>
                              {ques?.options.map((op, index) => (
                                <React.Fragment key={op.id}>
                                  <div>
                                    <input
                                      type="radio"
                                      id={`html${ques.id}`}
                                      name={`html${ques.id}`}
                                      value={op.id}
                                      onChange={(e) => {
                                        handleanswer(e, ques.id, ques.type);
                                      }}
                                    ></input>
                                     {" "}
                                    <label
                                      for={`html${ques.id}`}
                                      style={
                                        empty &&
                                        userInfo.answers[index].answer == "" &&
                                        ques.mandatory == 1
                                          ? { color: "red" }
                                          : {}
                                      }
                                    >
                                      {op.name}
                                    </label>
                                  </div>
                                </React.Fragment>
                              ))}
                            </div>
                          ) : ques.type == "checkbox" ? (
                            <div
                              className="col-md-6 "
                              style={{ marginBottom: "1rem" }}
                              name={`form${ques.id}`}
                            >
                              <p className="form-label">{ques.name} </p>
                              {ques.options.map((op, index) => (
                                <React.Fragment key={op.id}>
                                  <div>
                                    <input
                                      type="checkbox"
                                      id={`check${op.id}`}
                                      name="ckb"
                                      //disabled={checkchoices(ques.id,ques.minchoice,ques.maxchoice)}
                                      //checked={paymentInfo.answers[index]?.answer?.indexOf(op.id)!=-1}
                                      value={op.id}
                                      onChange={(e) => {
                                        handleanswer(e, ques.id, ques.type);
                                      }}
                                    ></input>
                                     {" "}
                                    <label
                                      for={`check${ques.id}`}
                                      style={
                                        empty &&
                                        !userInfo.answers[index].answer &&
                                        ques.mandatory == 1
                                          ? { color: " red" }
                                          : {}
                                      }
                                    >
                                      {op.name}
                                    </label>
                                  </div>
                                </React.Fragment>
                              ))}
                            </div>
                          ) : ques.type == "session" ? (
                            <div
                              className="col-md-6 "
                              style={{ marginBottom: "1rem" }}
                              name={`form${ques.id}`}
                            >
                              <p className="form-label">{ques.name} </p>
                              {ques.session_options.map((op, index) => (
                                <React.Fragment key={op.id}>
                                  <div>
                                    <input
                                      type="checkbox"
                                      id={`check${op.id}`}
                                      name="ckb"
                                      //disabled={checkchoices(ques.id,ques.minchoice,ques.maxchoice)}
                                      //checked={paymentInfo.answers[index]?.answer?.indexOf(op.id)!=-1}
                                      value={op.id}
                                      onChange={(e) => {
                                        handleanswer(e, ques.id, ques.type);
                                      }}
                                    ></input>
                                     {" "}
                                    <label
                                      for={`check${ques.id}`}
                                      style={
                                        empty &&
                                        !userInfo.answers[index].answer &&
                                        ques.mandatory == 1
                                          ? { color: " red" }
                                          : {}
                                      }
                                    >
                                      {op.title}
                                    </label>
                                  </div>
                                </React.Fragment>
                              ))}
                            </div>
                          ) : ques.type == "image" ? (
                            <div
                              className="col-md-6 form-label"
                              style={{ marginBottom: "1rem" }}
                            >
                              <p>{ques.name} </p>

                              <input
                                style={
                                  empty &&
                                  !userInfo.answers[index].answer &&
                                  ques.mandatory == 1
                                    ? { border: "1px solid red" }
                                    : userInfo?.answers[index]?.answer
                                    ? {
                                        background: "green",
                                        color: "white",
                                        width: "100%",
                                      }
                                    : {}
                                }
                                type="file"
                                name="logo"
                                accept=".png, .jpg, .jpeg, .svg"
                                onChange={async (e) => {
                                  const file = e.target.files[0];
                                  const base64 = await imageToBase64(file);
                                  console.log(base64);
                                  handleanswer(base64, ques.id, ques.type);
                                }}
                              />
                            </div>
                          ) : ques.type == "text" &&
                            (ques.name == "Country" ||
                              ques.name == "country") ? (
                            <>
                              <div
                                className="col-md-6 form-label"
                                style={{ marginBottom: "1rem" }}
                              >
                                <p>{ques.name} </p>
                                <select
                                  style={
                                    empty &&
                                    !userInfo.answers[index].answer &&
                                    ques.mandatory == 1
                                      ? { border: "1px solid red" }
                                      : {}
                                  }
                                  value={userInfo?.answers[index]?.answer}
                                  className="form-control-select"
                                  onChange={(e) => {
                                    handleanswer(e, ques.id, ques.type);
                                  }}
                                >
                                  <option value=""></option>
                                  {countryList.map((country, index) => (
                                    <option value={country}>{country}</option>
                                  ))}
                                </select>
                              </div>
                            </>
                          ) : (
                            <div
                              className="col-md-6 form-label"
                              style={{ marginBottom: "1rem" }}
                            >
                              <p>{ques.name} </p>
                              <input
                                style={
                                  empty &&
                                  !userInfo.answers[index].answer &&
                                  ques.mandatory == 1
                                    ? { border: "1px solid red" }
                                    : {}
                                }
                                value={userInfo?.answers[index]?.answer}
                                className="form-control"
                                onChange={(e) => {
                                  handleanswer(e, ques.id, ques.type);
                                }}
                              ></input>
                            </div>
                          )}
                        </React.Fragment>
                      ))}
                    </div>
                    {userInfo?.passcode == "" && selectedTicket?.price && (
                      <>
                        <div className="section-box">
                          <div
                            className=" section-box-title"
                            style={lang == "ar" ? { float: "right" } : {}}
                          >
                            <span>
                              {lang == "en" ? "Payment Method" : "طريقة الدفع"}
                            </span>
                          </div>
                        </div>
                        <div style={{ width: "100%", padding: "0 8%" }}>
                          <div
                            className="row"
                            style={{ width: "100%", marginBottom: "1rem" }}
                          >
                            <div className="col-md-6">
                              <label>
                                {lang == "en"
                                  ? "Choose Payment Method"
                                  : "إختر طريقة الدفع "}
                              </label>
                              <div>
                                <input
                                  style={
                                    empty && userInfo.payment_method == " "
                                      ? { border: "1px solid red" }
                                      : {}
                                  }
                                  type="radio"
                                  id="payment"
                                  name="payment"
                                  value={"online"}
                                  onChange={(e) => {
                                    setUserInfo({
                                      ...userInfo,
                                      payment_method: e.target.value,
                                    });
                                  }}
                                ></input>
                                 {" "}
                                <label
                                  for="payment"
                                  style={
                                    empty && userInfo.payment_method == ""
                                      ? { color: " red" }
                                      : {}
                                  }
                                >
                                  {lang == "en"
                                    ? "Debit/Credit Card"
                                    : "البطاقة الائتمناية"}
                                </label>
                              </div>
                              <div>
                                <input
                                  type="radio"
                                  id="payment"
                                  name="payment"
                                  value={"wallet"}
                                  onChange={(e) => {
                                    setUserInfo({
                                      ...userInfo,
                                      payment_method: e.target.value,
                                    });
                                  }}
                                ></input>
                                 {" "}
                                <label
                                  for="payment"
                                  style={
                                    empty && userInfo.payment_method == ""
                                      ? { color: " red" }
                                      : {}
                                  }
                                >
                                  {lang == "en"
                                    ? "Vodafone Cash"
                                    : "فودافون كاش"}
                                </label>
                                 
                              </div>
                            </div>
                          </div>
                          {userInfo.payment_method == "wallet" && (
                            <div className="row" style={{ width: "100%" }}>
                              <label>
                                {" "}
                                {lang == "en"
                                  ? "Wallet Mobile Number"
                                  : "رقم هاتف المحفظة"}
                              </label>
                              <input
                                style={
                                  empty && !userInfo.walletnumber
                                    ? { border: "1px solid red" }
                                    : {}
                                }
                                className="form-input"
                                value={userInfo.walletnumber}
                                type="number"
                                pattern="[0-9]*"
                                inputmode="numeric"
                                onChange={(e) => {
                                  setUserInfo({
                                    ...userInfo,
                                    walletnumber: e.target.value,
                                  });
                                }}
                              ></input>
                            </div>
                          )}
                        </div>
                      </>
                    )}
                    <div class="row mt-5">
                      <div class="col-md-6">
                        <div class="mb-3">
                          <button
                            class="form-btn-cancle page-info2 bold-font"
                            //onClick={PurchaseTicket}
                            onClick={() => {
                              navigate(`/${router.id}/purchase-ticket`);
                            }}
                          >
                            Back
                          </button>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="mb-3">
                          <button
                            class="form-btn page-info2 bold-font"
                            disabled={error}
                            style={error ? { color: "gray" } : {}}
                            onClick={PurchaseTicket}
                          >
                            {userInfo.quantity == 1 ? "Submit" : "Next"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt-5">
                  <div class="col-md-6"></div>
                </div>
              </div>
            ) : (
              <div class="col-md-6 half-side-page">
                <div class="px-5">
                  <div class="page-form mt-4">
                    {[...Array(userInfo.quantity - 1)].map((user, index) => (
                      <>
                        <p class="page-title5 blue-text bold-font mb-5">
                          Guest {index + 1}
                        </p>
                        <div class="row">
                          <div class="col-md-6">
                            <div class="mb-3">
                              <label for="fname" class="form-label">
                                Name
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                id="fname"
                                name="name"
                                placeholder=""
                                value={userInfo.guests[index].name}
                                onChange={(e) => ChangeGuest(e, index)}
                              />
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="mb-3">
                              <label for="phone" class="form-label">
                                Phone Number
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                id="phone"
                                name="mobileno"
                                placeholder=""
                                value={userInfo.guests[index].mobileno}
                                onChange={(e) => ChangeGuest(e, index)}
                              />
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-12">
                            <div class="mb-3">
                              <label for="mail" class="form-label">
                                Email Address
                              </label>
                              <input
                                type="email"
                                class="form-control"
                                id="mail"
                                name="email"
                                placeholder=""
                                value={userInfo.guests[index].email}
                                onChange={(e) => ChangeGuest(e, index)}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <label>
                            {lang == "en" ? "Ticket Type" : "نوع التذكرة"}
                          </label>
                          <select
                            style={
                              !userInfo?.tickets[index + 1]?.ticketid && error
                                ? { border: "1px solid red " }
                                : {}
                            }
                            onChange={(e) => handletick(e, index, false)}
                            className="form-control nav-selection-bar pr-3"
                          >
                            <option value={""}></option>
                            {order.map((ord, t) => (
                              <React.Fragment key={t}>
                                <option
                                  value={t}
                                  selected={
                                    userInfo.tickets[index + 1].ticketid ==
                                    ord.id
                                      ? true
                                      : false
                                  }
                                  disabled={
                                    ord.Taken != ord.quantity ? false : true
                                  }
                                >
                                  {ord.name}
                                </option>
                              </React.Fragment>
                            ))}
                          </select>
                        </div>

                        <div
                          className="row"
                          style={{ width: "100%", marginBottom: "1rem" }}
                        >
                          {record.require_questions == 1 && (
                            <div
                              className="row"
                              style={{ width: "100%", marginBottom: "1rem" }}
                            >
                              {record.questions?.map((ques, ind) => (
                                <React.Fragment key={ind}>
                                  {ques.type == "radiobutton" ? (
                                    <div
                                      className="col-md-6 "
                                      style={{ marginBottom: "1rem" }}
                                    >
                                      <p>
                                        {lang == "en"
                                          ? ques.name
                                          : ques.name_ar}
                                      </p>
                                      {ques?.options.map((op, id) => (
                                        <React.Fragment key={op.id}>
                                          <div>
                                            <input
                                              type="radio"
                                              id={`html${index}${ques.id}`}
                                              name={`html${index}${ques.id}`}
                                              value={op.id}
                                              onChange={(e) => {
                                                handleguestanswer(
                                                  e,
                                                  ques.id,
                                                  ques.type,
                                                  index
                                                );
                                              }}
                                            ></input>
                                             {" "}
                                            <label
                                              for={`html${index}${ques.id}`}
                                              style={
                                                empty &&
                                                userInfo.guests[index].answers[
                                                  ind
                                                ].answer == "" &&
                                                ques.mandatory == 1
                                                  ? { color: "red" }
                                                  : {}
                                              }
                                            >
                                              {lang == "en"
                                                ? op.name
                                                : op.name_ar}
                                            </label>
                                          </div>
                                        </React.Fragment>
                                      ))}
                                    </div>
                                  ) : ques.type == "checkbox" ? (
                                    <div
                                      className="col-md-6"
                                      style={{ marginBottom: "1rem" }}
                                    >
                                      <p>
                                        {lang == "en"
                                          ? ques.name
                                          : ques.name_ar}
                                      </p>
                                      {ques.options.map((op, i) => (
                                        <React.Fragment key={op.id}>
                                          <div>
                                            <input
                                              type="checkbox"
                                              id={`checkguest${index}${op.id}`}
                                              name={`check2${ques.id}`}
                                              value={op.id}
                                              onChange={(e) => {
                                                handleguestanswer(
                                                  e,
                                                  ques.id,
                                                  ques.type,
                                                  index
                                                );
                                              }}
                                            ></input>
                                             {" "}
                                            <label
                                              for={`check2${ques.id}`}
                                              style={
                                                empty &&
                                                !userInfo.guests[index].answers[
                                                  ind
                                                ].answer &&
                                                ques.mandatory == 1
                                                  ? { color: " red" }
                                                  : {}
                                              }
                                            >
                                              {lang == "en"
                                                ? op.name
                                                : op.name_ar}
                                            </label>
                                          </div>
                                        </React.Fragment>
                                      ))}
                                    </div>
                                  ) : ques.type == "session" ? (
                                    <div
                                      className="col-md-6 "
                                      style={{ marginBottom: "1rem" }}
                                      name={`form${ques.id}`}
                                    >
                                      <p className="form-label">{ques.name} </p>
                                      {ques.session_options.map((op, index) => (
                                        <React.Fragment key={op.id}>
                                          <div>
                                            <input
                                              type="checkbox"
                                              id={`check${op.id}`}
                                              name="ckb"
                                              //disabled={checkchoices(ques.id,ques.minchoice,ques.maxchoice)}
                                              //checked={paymentInfo.answers[index]?.answer?.indexOf(op.id)!=-1}
                                              value={op.id}
                                              onChange={(e) => {
                                                handleanswer(
                                                  e,
                                                  ques.id,
                                                  ques.type
                                                );
                                              }}
                                            ></input>
                                             {" "}
                                            <label
                                              for={`check${ques.id}`}
                                              style={
                                                empty &&
                                                !userInfo.answers[index]
                                                  .answer &&
                                                ques.mandatory == 1
                                                  ? { color: " red" }
                                                  : {}
                                              }
                                            >
                                              {op.title}
                                            </label>
                                          </div>
                                        </React.Fragment>
                                      ))}
                                    </div>
                                  ) : ques.type == "image" ? (
                                    <div
                                      className="col-md-6 form-label"
                                      style={{ marginBottom: "1rem" }}
                                    >
                                      <p>{ques.name} </p>

                                      <input
                                        style={
                                          empty &&
                                          !userInfo.answers[index].answer &&
                                          ques.mandatory == 1
                                            ? { border: "1px solid red" }
                                            : userInfo.answers[index].answer
                                            ? {
                                                background: "green",
                                                color: "white",
                                                width: "100%",
                                              }
                                            : {}
                                        }
                                        type="file"
                                        name="logo"
                                        accept=".png, .jpg, .jpeg, .svg"
                                        onChange={async (e) => {
                                          const file = e.target.files[0];
                                          const base64 = await imageToBase64(
                                            file
                                          );
                                          console.log(base64);
                                          handleguestanswer(
                                            e,
                                            ques.id,
                                            ques.type,
                                            index
                                          );
                                        }}
                                      />
                                    </div>
                                  ) : ques.type == "text" &&
                                    (ques.name == "Country" ||
                                      ques.name == "country") ? (
                                    <>
                                      <div
                                        className="col-md-6 form-label"
                                        style={{ marginBottom: "1rem" }}
                                      >
                                        <p>{ques.name} </p>
                                        <select
                                          style={
                                            empty &&
                                            !userInfo.guests[index].answers[ind]
                                              .answer &&
                                            ques.mandatory == 1
                                              ? { border: "1px solid red" }
                                              : {}
                                          }
                                          className="form-control-select"
                                          onChange={(e) => {
                                            handleguestanswer(
                                              e,
                                              ques.id,
                                              ques.type,
                                              index
                                            );
                                          }}
                                        >
                                          <option value=""></option>
                                          {countryList.map((country, index) => (
                                            <option value={country}>
                                              {country}
                                            </option>
                                          ))}
                                        </select>
                                      </div>
                                    </>
                                  ) : (
                                    <div
                                      className="col-md-12"
                                      style={{ marginBottom: "1rem" }}
                                    >
                                      <p>
                                        {lang == "en"
                                          ? ques.name
                                          : ques.name_ar}
                                      </p>
                                      <input
                                        style={
                                          empty &&
                                          !userInfo.guests[index].answers[ind]
                                            .answer &&
                                          ques.mandatory == 1
                                            ? { border: "1px solid red" }
                                            : {}
                                        }
                                        className="form-control"
                                        onChange={(e) => {
                                          handleguestanswer(
                                            e,
                                            ques.id,
                                            ques.type,
                                            index
                                          );
                                        }}
                                      ></input>
                                    </div>
                                  )}
                                </React.Fragment>
                              ))}
                            </div>
                          )}
                        </div>
                      </>
                    ))}
                    <div class="row mt-5">
                      <div class="col-md-6">
                        <div class="mb-3">
                          <button
                            class="form-btn-cancle page-info2 bold-font"
                            onClick={() => {
                              setPageNumber(1);
                            }}
                          >
                            Back
                          </button>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="mb-3">
                          <button
                            class="form-btn page-info2 bold-font"
                            disabled={error}
                            style={error ? { color: "gray" } : {}}
                            onClick={PurchaseTicket}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </main>

      <footer>
        <div class="footer-section">
          <div class="container">
            <div class="row mb-4">
              <div class="col-md-12 text-center">
                <a href="index.html">
                  <img
                    src="../assets/images/frontiers-logo.png"
                    class="frontiers-logo"
                    alt="Frontiers Logo"
                  />
                </a>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 offset-md-4 text-center">
                {record?.facebook_link != "#" && (
                  <a href={record.facebook_link} className="me-5 dark-text">
                    <i className="fa-brands fa-facebook-square page-info1"></i>
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
        <div class="footer-line2">
          <div class="container">
            <div class="row">
              <div class="col-md-12 text-center">
                <p class="mb-0 white-text">
                  © Frontiers. All rights reserved 2022 - Made By O-Projects
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default TicketForm;
