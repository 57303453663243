import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import $ from "jquery";
import "jquery-ui-bundle";
import "jquery-ui-bundle/jquery-ui.css";
import { Link, useNavigate } from "react-router-dom";
import { logOut } from "../api/Auth/actions";
import { HashLink } from "react-router-hash-link";
import { useParams } from "react-router";
function Navbar() {
  const dispatch = useDispatch();
  const router = useParams();
  const { isAuth, user, userTicket } = useSelector((state) => state.user);
  const { record } = useSelector((state) => state.event);
  const { sponsors, categories } = useSelector((state) => state.sponsors);
  const { booths } = useSelector((state) => state.booths);
  const { Speakers } = useSelector((state) => state.session);
  const [show, setShow] = useState(false);
  const nav = useNavigate();
  console.log(router.id, "tesst");
  const logout = () => {
    dispatch(logOut({ nav: nav, alias: record?.alias }));
  };
  console.log(record, "re");
  useEffect(() => {}, []);
  useEffect(() => {
    $(document).ready(function () {
      let navsidemenu = document.getElementById("nav-icon3");
      let navsidemenu2 = document.getElementById("mySidenav");
      $(navsidemenu).click(function () {
        //alert($(this).hasClass("open"));
        if (!$(this).hasClass("open")) {
          // $(this).toggleClass('navIcon3');
          $(this).toggleClass("open");
          openNav();
          return;
        } else {
          closeNav();
          navsidemenu?.classList.remove("open");
        }
      });
    });
  }, [show]);

  $(document).mouseup(function (e) {
    let navsidemenu = document.getElementById("nav-icon3");
    if ($(e.target) !== navsidemenu) {
      closeNav();
      navsidemenu?.classList.remove("open");
    }
  });

  // 	$('.open').click(function(){
  // 		closeNav();
  // 	});
  // });
  function openNav() {
    var element = document.getElementById("mySidenav");
    element?.classList.add("sidemenuWidth");
    console.log(element);
  }

  function closeNav() {
    var element = document.getElementById("mySidenav");
    element?.classList.remove("sidemenuWidth");

    console.log("closed");
  }
  return (
    <>
      <div>
        <head>
          <title>Welcome to Frontiers</title>
          <meta charSet="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <script src="path/to/jquery.js"></script>
          <script type="text/javascript"></script>

          <script src="path/to/jquery.js"></script>
          <link
            rel="stylesheet"
            href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0/css/all.min.css"
            integrity="sha512-9usAa10IRO0HhonpyAIVpjrylPvoDwiPUiKdWk5t3PyolY1cOd4DSE0Ga+ri4AuTroPR5aQvXU9xC6qOPnzFeg=="
            crossOrigin="anonymous"
            referrerPolicy="no-referrer"
          />
          {/* <link rel="stylesheet" type="text/css" href="assets/css/style.css" /> */}
        </head>
        <header>
          <nav class="navbar navbar-expand-sm bg-light justify-content-center fixed-top navi">
            <div class="container">
              <Link to={"/" + router?.id + ""}>
                <a class="navbar-brand">
                  <img
                    src={`https://frontiers.o-projects.org/storage/${record.logo}`}
                    class="logo"
                    alt="Frontiers Logo"
                  />
                </a>
              </Link>
              <ul class="navbar-nav ms-auto flex-row">
                {userTicket.length < 1 && (
                  <Link to={"/" + router?.id + "/purchase-ticket"}>
                    <li class="nav-item me-3">
                      <button class="nav-btn">Reserve Now</button>
                    </li>
                  </Link>
                )}

                <li class="nav-item">
                  <div
                    class="navIcon3"
                    id="nav-icon3"
                    onClick={() => setShow(!show)}
                  >
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </li>
              </ul>
              <div id="mySidenav" class="sidenav">
                <Link to={"/" + router?.id + ""}>
                  <a class="menu-link page-title3">
                    <span>Home</span>
                  </a>
                </Link>
                <HashLink smooth to={"/" + router?.id + "/#AgendaSection"}>
                  <a class="menu-link page-title3">
                    <span>Agenda</span>
                  </a>
                </HashLink>

                <Link smooth to={"/" + router?.id + "/announcements"}>
                  <a class="menu-link page-title3">
                    <span>Announcements</span>
                  </a>
                </Link>
                <Link smooth to={"/" + router?.id + "/sessions"}>
                  <a class="menu-link page-title3">
                    <span>Sessions</span>
                  </a>
                </Link>
                {Speakers?.length > 0 && (
                  <HashLink smooth to={"/" + router?.id + "/#SpeakersSection"}>
                    <a class="menu-link page-title3">
                      <span>Speakers</span>
                    </a>
                  </HashLink>
                )}

                {/* <a class="menu-link page-title3">
                  <span>Catalogue</span>
                </a> */}
                {booths?.length > 0 && (
                  <HashLink smooth to={"/" + router?.id + "/#BoothsSection"}>
                    <a class="menu-link page-title3">
                      <span>Booths</span>
                    </a>
                  </HashLink>
                )}
                {sponsors?.length > 0 && (
                  <HashLink smooth to={"/" + router?.id + "/#SponsorsSection"}>
                    <a class="menu-link page-title3">
                      <span>Sponsors</span>
                    </a>
                  </HashLink>
                )}
                <div class="pt-3 user-menu">
                  {!isAuth ? (
                    <>
                      {" "}
                      <Link to={"/" + router?.id + "/signin"}>
                        <a class="menu-link page-info3">
                          <span>Login</span>
                        </a>
                      </Link>
                      <Link to={"/" + router?.id + "/register"}>
                        <a class="menu-link page-info3">
                          <span>Sign up</span>
                        </a>
                      </Link>
                    </>
                  ) : (
                    <>
                      <a class="menu-link page-info3" onClick={logout}>
                        <span>Log Out</span>
                      </a>
                    </>
                  )}

                  {/* <!-- <a href="attendees.html" class="menu-link page-info3"><span>Announcements</span></a>
				  		<a href="attendees.html" class="menu-link page-info3"><span>Attendees</span></a> --> */}
                </div>
                <div class="d-flex justify-content-between social-media-menu">
                  {record?.facebook_link != "#" && (
                    <a href="" class="social-link">
                      <i class="fa-brands fa-facebook-square"></i>
                    </a>
                  )}
                </div>
              </div>
            </div>
          </nav>
        </header>
      </div>
    </>
  );
}

export default Navbar;
