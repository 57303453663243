import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Routes, Route, useNavigate, useParams } from "react-router-dom";
import { resetPass } from "../../api/Auth/actions";
import group31 from "../../assets/images/Group931.jpg";
import Navbar from "../../components/Navbar";

function ResetPass() {
  const [user, setUser] = useState();
  const { enteredEmail,code } = useSelector((state) => state.user);
  
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const router=useParams();
  const SubmitPassword = () => {
    const body = new FormData();
    let token = localStorage.getItem("user-token");
    body.append("code",code)
    body.append("password", user);
    dispatch(resetPass({ body, nav: navigate,alias:router.id }));
  };
 
  return (
    <div>
    <Navbar></Navbar>
      <main>
        <div class="container-fluid">
          <div class="row align-items-center">
            <div class="col-md-6 p-0">
              <div class="position-relative blue-header">
                <img src="../assets/images/Group931.jpg" class="side-img" />
                <div class="side-vertical-tabs">
                  <p class="side-menu-title text-center text-md-end pe-4">
                    New Password
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="px-5">
                <p class="page-title5 blue-text bold-font">
                  Create New Password
                </p>
                <p class="faded-black-text regular-font page-info2">
                  Type in your new password and confirm it
                </p>
                <div class="page-form">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="mb-3">
                        <label for="pwd" class="form-label">
                          Password
                        </label>
                        <input
                          type="password"
                          class="form-control"
                          id="pwd"
                          placeholder=""
                          value={user}
                          name="passowrd"
                          onChange={(e) =>
                            setUser( e.target.value,)
                          }
                        />
                      </div>
                    </div>
                  </div>
                  {/* <div class="row">
                    <div class="col-md-12">
                      <div class="mb-3">
                        <label for="cpwd" class="form-label">
                          Confirm Password
                        </label>
                        <input
                          type="password"
                          class="form-control"
                          id="cpwd"
                          placeholder=""
                          value={user.password_confirmation}
                          name="password_confirmation"
                          onChange={(e) =>
                            setUser({
                              ...user,
                              [e.target.name]: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                  </div> */}
                  <div class="row mt-5">
                    <div class="col-md-6">
                      <div class="mb-3">
                        <button
                          class="form-btn page-info2 bold-font"
                          onClick={SubmitPassword}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      <footer>
        <div class="footer-section">
          <div class="container">
            <div class="row mb-4">
              <div class="col-md-12 text-center">
                <a href="index.html">
                  <img
                   src="../assets/images/frontiers-logo.png"
                    class="frontiers-logo"
                    alt="Frontiers Logo"
                  />
                </a>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4 offset-md-4 text-center">
                <a href="" class="me-5 dark-text">
                  <i class="fa-brands fa-facebook-square page-info1"></i>
                </a>
                <a href="" class="me-5 dark-text">
                  <i class="fa-brands fa-instagram page-info1"></i>
                </a>
                <a href="" class="me-5 dark-text">
                  <i class="fa-brands fa-twitter page-info1"></i>
                </a>
                <a href="" class="dark-text">
                  <i class="fa-brands fa-linkedin page-info1"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="footer-line2">
          <div class="container">
            <div class="row">
              <div class="col-md-12 text-center">
                <p class="mb-0 white-text">
                  © Frontiers. All rights reserved 2022 - Made By O-Projects
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default ResetPass;
