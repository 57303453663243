import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getSponsorbyID, getSponsorTeam } from "../../api/Sponsers/actions";

function SponsorsDetails() {
  const { uuid } = useParams();
  const { sponsor, loading, team } = useSelector((state) => state.sponsors);
  const dispatch = useDispatch();
  useEffect(() => {
    if (uuid) {
      dispatch(getSponsorbyID({ id: uuid }));
      dispatch(getSponsorTeam({ id: uuid, params: "" }));

      window.scrollTo(0, 0);
    }
  }, [uuid]);

  console.log(uuid, "boothId");
  return (
    <div>
      {loading && (
        <>
          <div class="header-section">
            <img
              src="../../assets/images/hero-slider.jpg"
              class="d-block w-100 head-slide-img"
            />
            <div class="half-circle">
              <img
                src="../../assets/images/semi-circle.png"
                class="semi-circle"
              />

              <img
                src={`https://api.frontiers.events/storage/${sponsor?.logo}`}
                class="circle-logo"
              />
              <div class="overlay-info">
                <p class="white-text bold-font page-title3 mb-4">
                  About Company
                </p>
                <p class="white-text regular-font page-info2">
                  {sponsor?.about}
                </p>
                <p class="white-text regular-font">
                  <i class="fa-solid fa-location-dot"></i>
                  {sponsor?.address}
                </p>
                <p class="white-text regular-font">
                  <span class="pe-7s-global me-3 mb-4 page-info1"></span>{" "}
                  {sponsor?.website}
                </p>
                <div class="d-flex justify-content-between w-25 white-text text-center mx-auto">
                  <a href={`${sponsor?.facebook}`} class=" white-text">
                    <i class="fa-brands fa-facebook-f page-info1"></i>
                  </a>
                  <a href={`${sponsor?.instagram}`} class=" white-text">
                    <i class="fa-brands fa-instagram page-info1"></i>
                  </a>
                  <a href={`${sponsor?.youtube}`} class=" white-text">
                    <i class="fa-brands fa-youtube page-info1"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="section-top-space">
            <div class="container">
              <div class="row">
                <div class="col-md-10 offset-md-1 text-center">
                  <div class="video-section">
                    <div
                      dangerouslySetInnerHTML={{ __html: sponsor?.video }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {team?.length > 0 && (
            <div class="section-yaxis-space">
              <div class="container">
                <div class="row mb-5">
                  <div class="col-md-8 offset-md-2">
                    <p class="light-blue-text bold-font lined-title2 page-title1">
                      Our Team
                    </p>
                  </div>
                </div>
                {team.map((member, index) => (
                  <div class="row" key={index}>
                    <div class="col-md-8 offset-md-2">
                      <div class="d-flex justify-content-between">
                        <p class="regular-font black-text page-info3">
                          <img
                            src={`https://frontiers.o-projects.org/storage/${member.avatar}`}
                            class="user-img me-3"
                          />{" "}
                          {member.name}
                        </p>
                        <img
                          src="/assets/images/comments-o.svg"
                          class="side-icon"
                          onClick={() =>
                            window.open(`${member.chaturl}`, "_blank")
                          }
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}

          <footer>
            <div class="footer-section">
              <div class="container">
                <div class="row mb-4">
                  <div class="col-md-12 text-center">
                    <a href="index.html">
                      <img
                        src="../../assets/images/frontiers-logo.png"
                        class="frontiers-logo"
                        alt="Frontiers Logo"
                      />
                    </a>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4 offset-md-4 text-center">
                    <a href="" className="me-5 dark-text">
                      <i className="fa-brands fa-facebook-square page-info1"></i>
                    </a>
                    <a href="" className="me-5 dark-text">
                      <i className="fa-brands fa-instagram page-info1"></i>
                    </a>
                    <a href="" className="me-5 dark-text">
                      <i className="fa-brands fa-twitter page-info1"></i>
                    </a>
                    <a href="" className="dark-text">
                      <i className="fa-brands fa-linkedin page-info1"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="footer-line2">
              <div class="container">
                <div class="row">
                  <div class="col-md-12 text-center">
                    <p class="mb-0 white-text">
                      © Frontiers. All rights reserved 2022 - Made By O-Projects
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </>
      )}
    </div>
  );
}

export default SponsorsDetails;
