//import "../styles/globals.css";
//import "./assets/css/style.css";
import { Provider, useSelector } from "react-redux";
import { persistor, store } from "./store/store";
//import Script from "next/script";
import { Routes, Route, Link } from "react-router-dom";
import Home from "./pages/Home";
import SessionSpeakers from "./pages/sessions/SessionSpeakers";
import Register from "./pages/Auth/Register";
import SignIn from "./pages/Auth/SignIn";
import Navbar from "./components/Navbar";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PurchaseTicket from "./pages/Event/PurchaseTicket";
import ForgetPass from "./pages/Auth/ForgetPass";
import EnterCode from "./pages/Auth/EnterCode";
import TicketForm from "./pages/Event/TicketForm";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getEventsDetails } from "./api/Events/actions";
import { checkUser } from "./api/Auth/actions";
import BoothDetails from "./pages/Booth/BoothDetails";
import SponsorsDetails from "./pages/Sponsors/SponsorsDetails";
import Sessons from "./pages/sessions/Sessions";
import MySessions from "./pages/sessions/MySessions";
import PurchaseTicketByPromo from "./pages/Event/PurchaseTicketByPromo";
import { useParams } from "react-router";
import SessionDetails from "./pages/sessions/SessionDetails";
import Announcement from "./pages/Announcement/Announcement";
import ResetPass from "./pages/Auth/ResetPass";
import { RouteGuard } from "./components/RouterGaurd";
import { PersistGate } from "redux-persist/integration/react";
import Slots from "./pages/Booth/Slot";

function Routess({ Component, pageProps }) {
  const dispatch = useDispatch();
  const { isAuth, user } = useSelector((state) => state.user);
  const { record, agenda, loading, catalogue } = useSelector(
    (state) => state.event
  );

  useEffect(() => {
    //const id = localStorage.getItem("event_alias");

    //console.log(record,'appid')

    dispatch(getEventsDetails({ alias: record?.alias }));
    dispatch(checkUser());
  }, []);

  return (
    <>
      <Routes>
        <Route path="/:id" element={<Home />} />
        <Route
          path=":id/session-speakers"
          element={<SessionSpeakers></SessionSpeakers>}
        />
        <Route path=":id/register" element={<Register></Register>} />
        <Route path=":id/signin" element={<SignIn></SignIn>} />
        <Route
          path="/:id/purchase-ticket"
          element={<PurchaseTicket></PurchaseTicket>}
        ></Route>
        <Route
          path=":id/my-sessions"
          element={<MySessions></MySessions>}
        ></Route>
        <Route
          path=":id/announcements"
          element={<Announcement></Announcement>}
        ></Route>
        <Route
          path=":id/forget-passowrd"
          element={<ForgetPass></ForgetPass>}
        ></Route>
        <Route
          path=":id/reset-password"
          element={<ResetPass></ResetPass>}
        ></Route>
        <Route
          path=":id/purchase-ticket/promo"
          element={<PurchaseTicketByPromo></PurchaseTicketByPromo>}
        ></Route>
        <Route path=":id/Enter-Code" element={<EnterCode></EnterCode>}></Route>
        <Route
          path=":id/ticket-info"
          element={<TicketForm></TicketForm>}
        ></Route>
        <Route path=":id/sessions" element={<Sessons></Sessons>}></Route>
        <Route
          path="/:id/booths/:uuid"
          element={<BoothDetails></BoothDetails>}
        ></Route>
        <Route
          path="/:id/booths/:uuid/slots/:name"
          element={<Slots></Slots>}
        ></Route>
        <Route
          path="/:id/sessions/:uuid"
          element={<SessionDetails></SessionDetails>}
        ></Route>

        <Route
          path="/:id/sponsors/:uuid"
          element={<SponsorsDetails></SponsorsDetails>}
        ></Route>
      </Routes>

      <ToastContainer autoClose={5000} />
    </>
  );
}

export default Routess;
