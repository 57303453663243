import group31 from "../../assets/images/Group931.jpg";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
//import Router from "next/router";
import { userLogin, userRegister } from "../../api/Auth/actions";
import { Routes, Route, useNavigate } from "react-router-dom";
import { getEventsAgenda, getEventsDetails } from "../../api/Events/actions";
import { ToastContainer, toast, cssTransition } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const signin = {
  email: "",
  password: "",
  event_id: null,
};
const register = {
  email: "",
  password: "",
  first_name: "",
  last_name: "",
  mobileno: "",
  event_id: null,
};
function Register() {
  const navigate = useNavigate();
  const [loginUser, setLoginUser] = useState(signin);
  const [registerUser, setRegisterUser] = useState(register);
  const dispatch = useDispatch();
  const { record } = useSelector((state) => state.event);
  const router = useParams();
  useEffect(() => {
    if (router.id) dispatch(getEventsDetails({ alias: router.id }));
  }, []);
  const Login = () => {
    const formData = new FormData();
    formData.append("email", loginUser.email);
    formData.append("password", loginUser.password);
    formData.append("event_id", record?.id);
    dispatch(userLogin({ body: formData, nav: navigate, alias: router.id }));
  };
  const Register = () => {
    const formData = new FormData();
    formData.append("email", registerUser.email);
    formData.append("password", registerUser.password);
    formData.append("first_name", registerUser.first_name);
    formData.append("last_name", registerUser.last_name);
    formData.append("mobileno", registerUser.mobileno);
    formData.append("event_id", record?.id);
    dispatch(
      userRegister({ body: formData, nav: navigate, alias: router?.id })
    );
  };
  return (
    <>
      <head>
        <title>Welcome to Frontiers</title>
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </head>
      <body>
        <main>
          <div class="container-fluid d-md-block d-none">
            <div class="row align-items-center">
              <div class="col-md-6 p-0">
                <div class="position-relative">
                  <img src={group31} class="side-img" />
                  <ul
                    class="nav nav-tabs flex-column side-vertical-tabs"
                    id="myTab"
                    role="tablist"
                  >
                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link  pe-4 text-center text-md-end"
                        id="signin-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#signin"
                        type="button"
                        role="tab"
                        aria-controls="signin"
                        aria-selected="true"
                      >
                        Sign in
                      </button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link active pe-4 text-center text-md-end"
                        id="register-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#register"
                        type="button"
                        role="tab"
                        aria-controls="register"
                        aria-selected="false"
                      >
                        Register
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-md-6">
                <div class="tab-content" id="myTabContent">
                  <div
                    class="tab-pane fade show  px-5"
                    id="signin"
                    role="tabpanel"
                    aria-labelledby="signin-tab"
                  >
                    <p class="page-title5 blue-text bold-font mb-4">Sign In</p>
                    <div class="page-form">
                      <div class="row">
                        <div class="col-md-12">
                          <div class="mb-3">
                            <label for="mail" class="form-label">
                              Email Address
                            </label>
                            <input
                              type="email"
                              class="form-control"
                              id="mail"
                              placeholder=""
                              name="email"
                              value={loginUser.email}
                              onChange={(e) => {
                                setLoginUser({
                                  ...loginUser,
                                  [e.target.name]: e.target.value,
                                });
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12">
                          <div class="mb-3">
                            <label for="pwd" class="form-label">
                              Password
                            </label>
                            <input
                              type="password"
                              class="form-control"
                              id="pwd"
                              placeholder=""
                              name="password"
                              value={loginUser.password}
                              onChange={(e) => {
                                setLoginUser({
                                  ...loginUser,
                                  [e.target.name]: e.target.value,
                                });
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6 col-6">
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              id="flexCheckChecked"
                              checked
                            />
                            <label
                              class="form-check-label black-text regular-font"
                              for="flexCheckChecked"
                            >
                              Remember Me
                            </label>
                          </div>
                        </div>
                        <div class="col-md-6 col-6 text-end">
                          <Link to="/forget-passowrd">
                            <a
                              //href="forgot-password.html"
                              class="blue-text bold-font page-link1"
                            >
                              Forgot Password?
                            </a>
                          </Link>
                        </div>
                      </div>
                      <div class="row mt-5">
                        <div class="col-md-6">
                          <div class="mb-3">
                            <button
                              class="form-btn page-info2 bold-font"
                              onClick={Login}
                            >
                              Sign in
                            </button>
                          </div>
                        </div>
                      </div>
                      {/* <div class="row">
                        <div class="col-md-6 col-6">
                          <button class="form-side-btn regular-font">
                            <i class="fa-brands fa-google me-2"></i> Connect
                            Using Google
                          </button>
                        </div>
                        <div class="col-md-6 col-6 text-end">
                          <button class="form-side-btn regular-font">
                            <i class="fa-brands fa-facebook-f me-2"></i> Connect
                            Using Facebook
                          </button>
                        </div>
                      </div> */}
                    </div>
                  </div>
                  <div
                    class="tab-pane  fade show active px-5"
                    id="register"
                    role="tabpanel"
                    aria-labelledby="register-tab"
                  >
                    <p class="page-title5 blue-text bold-font mb-4">
                      Create New Account
                    </p>
                    <div class="page-form">
                      <div class="container">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="mb-3">
                              <label for="fname" class="form-label">
                                First Name
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                id="fname"
                                placeholder=""
                                name="first_name"
                                value={registerUser.first_name}
                                onChange={(e) => {
                                  setRegisterUser({
                                    ...registerUser,
                                    [e.target.name]: e.target.value,
                                  });
                                }}
                              />
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="mb-3">
                              <label for="lname" class="form-label">
                                Last Name
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                id="lname"
                                placeholder=""
                                name="last_name"
                                value={registerUser.last_name}
                                onChange={(e) => {
                                  setRegisterUser({
                                    ...registerUser,
                                    [e.target.name]: e.target.value,
                                  });
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-12">
                            <div class="mb-3">
                              <label for="mail" class="form-label">
                                Email Address
                              </label>
                              <input
                                type="email"
                                class="form-control"
                                id="mail"
                                placeholder=""
                                name="email"
                                value={registerUser.email}
                                onChange={(e) => {
                                  setRegisterUser({
                                    ...registerUser,
                                    [e.target.name]: e.target.value,
                                  });
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-12">
                            <div class="mb-3">
                              <label for="phone" class="form-label">
                                Phone Number
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                id="phone"
                                placeholder=""
                                name="mobileno"
                                value={registerUser.mobileno}
                                onChange={(e) => {
                                  setRegisterUser({
                                    ...registerUser,
                                    [e.target.name]: e.target.value,
                                  });
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6">
                            <div class="mb-3">
                              <label for="pwd" class="form-label">
                                Password
                              </label>
                              <input
                                type="password"
                                class="form-control"
                                id="pwd"
                                placeholder=""
                                name="password"
                                value={registerUser.password}
                                onChange={(e) => {
                                  setRegisterUser({
                                    ...registerUser,
                                    [e.target.name]: e.target.value,
                                  });
                                }}
                              />
                            </div>
                          </div>
                          {/* <div class="col-md-6">
                            <div class="mb-3">
                              <label for="cpwd" class="form-label">
                                Confirm Password
                              </label>
                              <input
                                type="password"
                                class="form-control"
                                id="cpwd"
                                placeholder=""
                              />
                            </div>
                          </div> */}
                        </div>
                        <div class="row mt-5">
                          <div class="col-md-6">
                            <button
                              class="form-btn page-info2 bold-font"
                              onClick={Register}
                            >
                              Register
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mobile-bg d-md-none d-block">
            <div class="section-yaxis-space">
              <div class="mobile-form-title text-center mb-5">
                <p class="blue-text black-font py-3 mb-0 text-uppercase page-title3">
                  Register
                </p>
              </div>
              <div class="container-fluid">
                <div class="page-form">
                  <div class="container">
                    <div class="row">
                      <div class="col-6">
                        <div class="mb-3">
                          <label for="fname" class="form-label">
                            First Name
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            id="fname"
                            placeholder=""
                            name="first_name"
                            value={registerUser.first_name}
                            onChange={(e) => {
                              setRegisterUser({
                                ...registerUser,
                                [e.target.name]: e.target.value,
                              });
                            }}
                          />
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="mb-3">
                          <label for="lname" class="form-label">
                            Last Name
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            id="lname"
                            placeholder=""
                            name="last_name"
                            value={registerUser.last_name}
                            onChange={(e) => {
                              setRegisterUser({
                                ...registerUser,
                                [e.target.name]: e.target.value,
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="mb-3">
                          <label for="mail" class="form-label">
                            Email Address
                          </label>
                          <input
                            type="email"
                            class="form-control"
                            id="mail"
                            placeholder=""
                            name="email"
                            value={registerUser.email}
                            onChange={(e) => {
                              setRegisterUser({
                                ...registerUser,
                                [e.target.name]: e.target.value,
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="mb-3">
                          <label for="phone" class="form-label">
                            Phone Number
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            id="phone"
                            placeholder=""
                            name="mobileno"
                            value={registerUser.mobileno}
                            onChange={(e) => {
                              setRegisterUser({
                                ...registerUser,
                                [e.target.name]: e.target.value,
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="mb-3">
                          <label for="pwd" class="form-label">
                            Password
                          </label>
                          <input
                            type="password"
                            class="form-control"
                            id="pwd"
                            placeholder=""
                            name="password"
                            value={registerUser.password}
                            onChange={(e) => {
                              setRegisterUser({
                                ...registerUser,
                                [e.target.name]: e.target.value,
                              });
                            }}
                          />
                        </div>
                      </div>
                      {/*  <div class="col-md-6">
                        <div class="mb-3">
                          <label for="cpwd" class="form-label">
                            Confirm Password
                          </label>
                          <input
                            type="password"
                            class="form-control"
                            id="cpwd"
                            placeholder=""
                          />
                        </div>
                      </div>*/}
                    </div>
                    {/* <div class="row">
                      <div class="col-12">
                        <div class="mb-3">
                          <label for="work" class="form-label">
                            Gender
                          </label>
                          <div class="input-group">
                            <div class="mobile-select w-100">
                              <select
                                class="form-control nav-selection-bar pr-3"
                                id="work"
                                placeholder=""
                              >
                                <option
                                  selected="true"
                                  disabled="true"
                                ></option>
                                <option>Male</option>
                                <option>Female</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                    {/* <div class="row">
                      <div class="col-12">
                        <div class="mb-3">
                          <label for="work" class="form-label">
                            Age Group
                          </label>
                          <div class="input-group">
                            <div class="mobile-select w-100">
                              <select
                                class="form-control nav-selection-bar pr-3"
                                id="work"
                                placeholder=""
                              >
                                <option
                                  selected="true"
                                  disabled="true"
                                ></option>
                                <option>15 - 20</option>
                                <option>21 - 25</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <div class="mb-3">
                          <label for="work" class="form-label">
                            Reason for Attending
                          </label>
                          <div class="input-group">
                            <div class="mobile-select w-100">
                              <select
                                class="form-control nav-selection-bar pr-3"
                                id="work"
                                placeholder=""
                              >
                                <option
                                  selected="true"
                                  disabled="true"
                                ></option>
                                <option>Learning</option>
                                <option>Work</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                    <div class="row mt-5">
                      <div class="col-12">
                        <div class="mb-3">
                          <button
                            class="side-btn3 w-100 page-info2 bold-font"
                            onClick={Register}
                          >
                            Register
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12 text-center">
                        <div class="mb-3">
                          <p class="bold-font white-text">
                            Do You Have An Account?{" "}
                            <Link to={"/" + router.id + "/signin"}>
                              <a class="light-blue-text">Sign In Here</a>
                            </Link>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>

        <footer>
          <div class="footer-section">
            <div class="container">
              <div class="row mb-4">
                <div class="col-md-12 text-center">
                  <a href="index.html">
                    <img
                      src="../assets/images/frontiers-logo.png"
                      class="frontiers-logo"
                      alt="Frontiers Logo"
                    />
                  </a>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4 offset-md-4 text-center">
                  {record?.facebook_link != "#" && (
                    <a href={record.facebook_link} className="me-5 dark-text">
                      <i className="fa-brands fa-facebook-square page-info1"></i>
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div class="footer-line2">
            <div class="container">
              <div class="row">
                <div class="col-md-12 text-center">
                  <p class="mb-0 white-text">
                    © Frontiers. All rights reserved 2022 - Made By O-Projects
                  </p>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </body>
    </>
  );
}

export default Register;
