import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
export const getSponsors = createAsyncThunk("get/sponsors", async (params) => {
  try {
    const response = await axios.get(
      "https://frontiers.o-projects.org/api/website/event/sponsors",
      { params }
    );
    console.log(response);
    return response.data;
  } catch (err) {
    //return rejectWithValue(err.response.data);
  }
});
export const getSponsorTeam = createAsyncThunk(
  "get/sponsorteambyId",
  async ({ id, params }) => {
    try {
      console.log(params);
      const response = await axios.get(
        "https://frontiers.o-projects.org/api/website/event/sponsors/team/list?id=" +
          id +
          "",
        { params }
      );
      console.log(response, "teaaammss");
      return response.data;
    } catch (err) {
      //return rejectWithValue(err.response.data);
    }
  }
);
export const getSponsorbyID = createAsyncThunk(
  "get/sponsorbyId",
  async (params) => {
    try {
      console.log(params);
      const response = await axios.get(
        "https://frontiers.o-projects.org/api/website/event/sponsors/details",
        { params }
      );
      console.log(response);
      return response.data;
    } catch (err) {
      //return rejectWithValue(err.response.data);
    }
  }
);
export const getSponsorCategories = createAsyncThunk(
  "get/sponsorCategories",
  async (params) => {
    try {
      console.log(params);
      const response = await axios.get(
        "https://frontiers.o-projects.org/api/website/event/sponsors/catlist",
        { params }
      );
      console.log(response);
      return response.data;
    } catch (err) {
      //return rejectWithValue(err.response.data);
    }
  }
);
