import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
export const getBooths = createAsyncThunk("get/booths", async (params) => {
  try {
    console.log(params);
    const response = await axios.get(
      "https://frontiers.o-projects.org/api/website/event/booths",
      { params }
    );
    console.log(response);
    return response.data;
  } catch (err) {
    //return rejectWithValue(err.response.data);
  }
});
export const getBoothbyID = createAsyncThunk(
  "get/boothbyId",
  async (params) => {
    try {
      console.log(params);
      const response = await axios.get(
        "https://frontiers.o-projects.org/api/website/event/booths/details",
        { params }
      );
      console.log(response);
      return response.data;
    } catch (err) {
      //return rejectWithValue(err.response.data);
    }
  }
);
export const getBoothTeam = createAsyncThunk(
  "get/boothteambyId",
  async ({ id, params }) => {
    try {
      console.log(params);
      const response = await axios.get(
        "https://frontiers.o-projects.org/api/website/booth/" +
          id +
          "/sales/list",
        { params }
      );
      console.log(response, "teaaammss");
      return response.data;
    } catch (err) {
      //return rejectWithValue(err.response.data);
    }
  }
);
export const getBoothslots = createAsyncThunk(
  "get/boothslot",
  async ({ name }) => {
    try {
      let token = localStorage.getItem("user-token");

      console.log(name);
      const response = await axios({
        method: "get",
        url:
          "https://frontiers.o-projects.org/api/event/booths/exhibitor-sales-timelines/" +
          name +
          "/timelines/list",
        headers: { Authorization: `Bearer ${token}` },
      });
      console.log(response, "teaaammss");
      return response.data;
    } catch (err) {
      //return rejectWithValue(err.response.data);
    }
  }
);
export const AddSlotToMyAgenda = createAsyncThunk(
  "add/slot",
  async ({ id, name }, { dispatch }) => {
    try {
      console.log(name, "hi");
      let token = localStorage.getItem("user-token");

      const response = await axios({
        method: "post",
        url: `https://frontiers.o-projects.org/api/website/booth/timeline/${id}/reserve`,
        headers: { Authorization: `Bearer ${token}` },
      });

      console.log(response);
      toast.success("Slot Added Successfuly");
      dispatch(getMybooths());

      dispatch(getBoothslots({ name }));

      return response.data;
    } catch (err) {
      //return rejectWithValue(err.response.data);
      console.log(err);
      toast.error(err.response.data.message);
    }
  }
);
export const getMybooths = createAsyncThunk("get/mybooths", async (params) => {
  try {
    let token = localStorage.getItem("user-token");
    console.log(params, "usertoken");
    var headers = {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };
    const response = await axios.get(
      "https://frontiers.o-projects.org/api/website/booth/timeline/list",
      { headers }
    );
    console.log(response);
    return response.data;
  } catch (err) {
    //return rejectWithValue(err.response.data);
  }
});
export const DeleteBoothToMyAgenda = createAsyncThunk(
  "delete/mybooth",
  async ({ id, name }, { dispatch }) => {
    try {
      let token = localStorage.getItem("user-token");

      const response = await axios({
        method: "delete",
        url: `https://frontiers.o-projects.org/api/website/booth/timeline/${id}/remove`,
        headers: { Authorization: `Bearer ${token}` },
      });

      console.log(response);
      toast.success("Slot Deleted Successfuly");
      dispatch(getMybooths());
      dispatch(getBoothslots({ name }));

      return response.data;
    } catch (err) {
      //return rejectWithValue(err.response.data);
      toast.error(err.response.data.error);
    }
  }
);
