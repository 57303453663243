//import "./App.css";
//import "./assets/css/style.css";
import $ from "jquery";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { getBooths } from "../api/Booths/actions";
import { getEventsAgenda, getEventsDetails } from "../api/Events/actions";
import { getPartners } from "../api/Partners/actions";
import { getSponsorCategories, getSponsors } from "../api/Sponsers/actions";
import { getAllSpeakers } from "../api/Sessions/actions";
import Modal from "react-modal";
import Navbar from "../components/Navbar";
import { getTicketCode } from "../api/Tickets/actions";
import HTMLFlipBook from "react-pageflip";
import { useParams } from "react-router";
import {
  pdfjs,
  Document,
  Page as ReactPdfPage,
} from "react-pdf/dist/esm/entry.webpack";
//import { pdfjs } from 'react-pdf';
import samplePDF from "../assets/images/sample.pdf";
import Loader from "../components/Loader";
import { getuserTickets } from "../api/Auth/actions";

const Page = React.forwardRef(({ pageNumber }, ref) => {
  return (
    <div ref={ref}>
      <ReactPdfPage pageNumber={pageNumber} className="page" />
    </div>
  );
});
function Home() {
  const dispatch = useDispatch();
  const { record, agenda, loading, catalogue, ticketTypes } = useSelector(
    (state) => state.event
  );
  const { partners } = useSelector((state) => state.partner);
  const { sponsors, categories } = useSelector((state) => state.sponsors);
  const { booths } = useSelector((state) => state.booths);
  const { user, userTicket } = useSelector((state) => state.user);

  const { Speakers } = useSelector((state) => state.session);
  const { Message, isFound } = useSelector((state) => state.ticket);
  const [redeemModal, setRedeemModal] = useState(false);
  const [redem, setRedem] = useState(false);
  const [cat, setCat] = useState("");
  const [boothsArr, setBoothsArr] = useState([]);
  const [code, setCode] = useState("");
  const nav = useNavigate();
  const [boothSearch, setBoothSearch] = useState("");
  const width = 500;
  const height = 100;
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [pdfUrl, setPdfUrl] = useState("");
  const router = useParams();

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;
  console.log(router, "iddd");

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      width: "23.188rem",
      height: "15.125rem",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  useEffect(() => {
    if (router.id) {
      dispatch(getEventsDetails({ alias: `${router.id}` }));
    }
  }, [record.id]);
  useEffect(() => {
    let body = {
      mobileno: user?.mobileno,
    };
    dispatch(getuserTickets({ body, id: record.id }));
  }, [user, record.id]);
  useEffect(() => {
    if (record) {
      dispatch(getEventsAgenda({ event_id: record?.id }));
      dispatch(getPartners({ event_id: record?.id }));
      dispatch(getSponsors({ event_id: record?.id, category: cat }));
      dispatch(getBooths({ event_id: record?.id }));
      dispatch(getSponsorCategories({ event_id: record?.id }));
      dispatch(getAllSpeakers({ event_id: record?.id }));
    }
  }, [record]);

  console.log(Speakers, "speakers");
  useEffect(() => {
    if (booths) {
      setBoothsArr(booths);
    }
  }, [booths]);
  const getValues = (obj, txt) => {
    let res = txt;
    for (var key in obj) {
      if (typeof obj[key] === "object") {
        res += getValues(obj[key], res) + "-";
      } else {
        res += obj[key] + "-";
      }
    }
    return res;
  };
  useEffect(() => {
    if (ticketTypes.length > 0) {
      for (let i = 0; i < ticketTypes.length; i++) {
        if (ticketTypes[i].price || ticketTypes[i].price !== 0) {
          setRedem(true);
          return;
        } else {
          setRedem(false);
        }
      }
    }
  }, [ticketTypes]);
  useEffect(() => {
    const data = booths.filter((name: any) =>
      getValues(name, "").toLowerCase().includes(boothSearch.toLowerCase())
    );
    setBoothsArr(data);
  }, [boothSearch]);
  const redeemTicket = () => {
    let params = {
      event_id: record?.id,
      code: code,
    };
    dispatch(getTicketCode({ params: params, nav: nav, alias: router?.id }));
  };
  //  useEffect(()=>{
  //   if(isFound==true)
  //    {
  //      nav("/purchase-ticket/promo");
  //    }
  // },[isFound])
  console.log(sponsors, "pagenum");
  return (
    <>
      {loading ? (
        <Loader></Loader>
      ) : (
        <>
          <Navbar></Navbar>
          <Modal
            isOpen={redeemModal}
            onRequestClose={() => {
              setRedeemModal(false);
            }}
            style={customStyles}
          >
            <div>
              <div
                className="flex-center"
                style={{
                  color: "#2B6EC8",
                  fontSize: "1rem",
                  fontWeight: "bold",
                }}
              >
                Redeem Ticket
              </div>
              <div style={{ padding: "2.25rem" }}>
                <input
                  type="text"
                  class="form-control"
                  id="job"
                  placeholder=""
                  name="code"
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                ></input>
                <div style={{ color: "red" }}>{Message}</div>
              </div>

              <div className="d-flex" style={{ justifyContent: "center" }}>
                <button
                  style={{
                    width: "10.8rem",
                    height: "3rem",
                    color: "white",
                    background: "#2B6EC8 0% 0% no-repeat padding-box",
                    borderRadius: "12px",
                    opacity: "1",
                    border: "none",
                    margin: "0 2rem",
                  }}
                  onClick={redeemTicket}
                >
                  Redeem
                </button>
                <button
                  style={{
                    width: "10.8rem",
                    height: "3rem",
                    color: "black",
                    backGround: "white 0% 0% no-repeat padding-box",
                    borderRadius: "12px",
                    opacity: "1",
                    border: "none",
                  }}
                  onClick={() => setRedeemModal(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Modal>
          <body>
            <main>
              <div
                id="carouselExampleIndicators"
                className="carousel slide head-slider"
                data-bs-ride="carousel"
              >
                <div className="carousel-indicators">
                  {record.slideshow?.map((banner, index) => (
                    <button
                      type="button"
                      data-bs-target="#carouselExampleIndicators"
                      data-bs-slide-to={`${index}`}
                      className={index == 0 ? "active" : ""}
                      aria-current={index == 0 ? "true" : ""}
                      aria-label={`Slide ${index}`}
                    ></button>
                  ))}
                </div>
                <div className="carousel-inner">
                  {record.slideshow?.map((banner, index) => (
                    <div
                      key={index}
                      className={
                        index == 0 ? "carousel-item active" : "carousel-item"
                      }
                      style={{ height: "100vh" }}
                    >
                      <img
                        src={`https://frontiers.o-projects.org/storage/${banner.image}`}
                        className="d-block w-100 head-slide-img"
                        alt={`Slide ${index}`}
                        style={{ height: "100%", objectFit: "fill" }}
                      />
                      <div className="head-overlay">
                        <p className="blue-text bold-font page-title1"></p>
                        <p
                          className="white-text regular-font page-info3"
                          dangerouslySetInnerHTML={{
                            __html: banner?.shortdesc,
                          }}
                        ></p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div className="section-yaxis-space">
                <div className="container">
                  <div className="row mb-3">
                    <div className="col-md-12 text-center">
                      <p className="blue-text bold-font page-title1 lined-title">
                        Event Information
                      </p>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-md-8 offset-md-2 text-center">
                      <p
                        className="dark-text regular-font  page-info1"
                        dangerouslySetInnerHTML={{
                          __html: record?.description,
                        }}
                      ></p>
                    </div>
                  </div>
                  {userTicket.length < 1 && (
                    <div className="row mb-3">
                      <div className="col-md-12 text-center">
                        <Link to={"/" + router.id + "/purchase-ticket"}>
                          <button className="page-btn">
                            get your invitation
                          </button>
                        </Link>
                      </div>
                    </div>
                  )}

                  {redem && (
                    <div className="row mb-3">
                      <div className="col-md-12 text-center">
                        <a
                          className="blue-text bold-font page-link1 page-info2"
                          style={{ cursor: "pointer" }}
                          onClick={() => setRedeemModal(true)}
                        >
                          Redeem A Ticket
                        </a>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {record?.sections?.map((sec, index) => (
                <>
                  {sec.type != 2 && sec.type != 3 && (
                    <div>
                      {sec.type == 8 ? (
                        <div className="container">
                          <div className="row mb-5">
                            <div className="col-md-12 text-center">
                              <p className="blue-text bold-font page-title1 lined-title">
                                {sec.title}
                              </p>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                margin: "0.5rem 0",
                              }}
                            >
                              <img
                                src={`https://api.frontiers.events/storage/${sec.extra}`}
                              ></img>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                              dangerouslySetInnerHTML={{
                                __html: sec?.description,
                              }}
                            ></div>
                          </div>
                        </div>
                      ) : (
                        <div className="container">
                          <div className="row mb-5">
                            <div className="col-md-12 text-center">
                              <p className="blue-text bold-font page-title1 lined-title">
                                {sec.title}
                              </p>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                              className="embed-responsive embed-responsive-16by9"
                              dangerouslySetInnerHTML={{
                                __html: sec?.description,
                              }}
                            ></div>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </>
              ))}
              {partners.length > 0 && (
                <div className="brands-section" id="PartnersSection">
                  <div className="container">
                    <div className="row align-items-center partners-div">
                      <div className="col-md-3 offset-md-0 col-12 text-center">
                        <p className="dark-text bold-font page-title2 mb-1">
                          Our Partners
                        </p>
                      </div>
                      <div className="col-md-9">
                        <div className="scroll-brands">
                          {partners.map((partner, index) => (
                            <div className="col-md-3 col-6 text-center">
                              <img
                                src={`https://api.frontiers.events/storage/${partner.logo}`}
                                alt="Aven"
                                className="brand-img"
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className="agenda-section" id="AgendaSection">
                <div className="container">
                  <div className="row mb-3 align-items-center align-items-md-start">
                    <div className="col">
                      <p className="white-text bold-font page-title1 mb-0">
                        Event Agenda
                      </p>
                    </div>
                    <div class="col-xl-4 col-lg-4 text-center">
                      <div class="d-flex align-items-center justify-content-between">
                        <a
                          href={"/" + router.id + "/my-sessions"}
                          class="white-text bold-font page-link1"
                        >
                          View My Sessions
                        </a>
                        <Link to={"/" + router.id + "/sessions"}>
                          <button className="side-btn float-end">
                            View Sessions
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                  {agenda?.map((agend, index) => (
                    <div key={index} className="row align-items-center">
                      <div className="col-md-12">
                        <div
                          className="accordion page-accordion"
                          id="agendaAccordion"
                        >
                          <div className="accordion-item">
                            <h2 className="accordion-header" id="headingOne">
                              <button
                                className="accordion-button bold-font"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#day1"
                                aria-expanded="true"
                                aria-controls="day1"
                              >
                                {agend?.date}
                              </button>
                            </h2>
                            <div
                              id="day1"
                              className="accordion-collapse collapse show"
                              aria-labelledby="headingOne"
                              data-bs-parent="#agendaAccordion"
                            >
                              <div className="accordion-body">
                                <p
                                  className="bold-font"
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    nav("/" + router.id + "/my-sessions")
                                  }
                                >
                                  Day {agend?.title}
                                </p>
                                <p className="regular-font">
                                  {agend?.description}
                                </p>
                                {agend?.sessions?.map((session, index) => (
                                  <ul key={index} className="regular-font">
                                    <li>{session?.title}</li>
                                  </ul>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              {agenda.length > 0 && (
                <div className="section-top-space" id="SpeakersSection">
                  <div className="container">
                    <div className="row mb-3">
                      <div className="col-md-12 text-center">
                        <p className="blue-text bold-font page-title1 lined-title">
                          Our Sessions
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="cards-brands-slider">
                    {agenda?.map((agend, index) => (
                      <React.Fragment key={index}>
                        {agend?.sessions?.map((session, index) => (
                          <div
                            className="item"
                            key={index}
                            style={{ cursor: "pointer" }}
                            onClick={() => nav(`/${router.id}/sessions`)}
                          >
                            <div className="card page-card up-view">
                              <div className="card-body">
                                <img
                                  src={`https://frontiers.o-projects.org/storage/${session.logo}`}
                                  className="speaker-image"
                                  alt="Development Icon"
                                />
                                <div className="card-div">
                                  <p
                                    className="card-title regular-font page-title3 mb-0"
                                    style={{
                                      height: "24%",
                                      fontSize: "1.1rem",
                                    }}
                                  >
                                    {session.title}
                                  </p>
                                  <br />
                                  <p
                                    className="card-info bold-font mb-0 page-title4"
                                    style={{ fontSize: "1.1rem" }}
                                    dangerouslySetInnerHTML={{
                                      __html: session.description,
                                    }}
                                  ></p>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </React.Fragment>
                    ))}
                  </div>
                </div>
              )}
              {Speakers.length > 0 && (
                <div className="section-top-space" id="SpeakersSection">
                  <div className="container">
                    <div className="row mb-3">
                      <div className="col-md-12 text-center">
                        <p className="blue-text bold-font page-title1 lined-title">
                          Our Speakers
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="cards-brands-slider">
                    {Speakers.map((speaker, index) => (
                      <div className="item" key={index}>
                        <div className="card page-card up-view">
                          <div className="card-body">
                            <img
                              src={`https://frontiers.o-projects.org/storage/${speaker.avatar}`}
                              className="speaker-image"
                              alt="Development Icon"
                            />
                            <div className="card-div">
                              <p
                                className="card-title regular-font page-title3 mb-0"
                                style={{ height: "24%", fontSize: "1.1rem" }}
                              >
                                {speaker.name}
                              </p>
                              <br />
                              <p
                                className="card-info bold-font mb-0 page-title4"
                                style={{ fontSize: "1.1rem" }}
                              >
                                {speaker.title}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {/* <div className="section-yaxis-space" id="CatalogueSection">
                <div className="container">
                  <div className="row mb-5">
                    <div className="col-md-12 text-center">
                      <p className="blue-text bold-font page-title1 lined-title">
                        Our Catalogue
                      </p>
                    </div>
                  </div>
                  <div
                    className="row"
                    style={{ display: "flex", justifyContent: "end" }}
                  >
                    <div className="col-md-8 offset-md-2">
                      {pdfUrl != "" && (
                        <Document
                          file={pdfUrl}
                          onLoadSuccess={onDocumentLoadSuccess}
                        >
                          <HTMLFlipBook
                            width={400}
                            height={500}
                            showCover={true}
                            maxShadowOpacity={0.5}
                            mobileScrollSupport={true}
                            style={{ background: "#b4aaaa0d" }}
                          >
                            {[...Array(numPages)].map((page, index) => (
                              <Page pageNumber={index + 1} />
                            ))}
                          </HTMLFlipBook>
                        </Document>
                      )}
                    </div>
                  </div>
                </div>
              </div> */}
              {sponsors.length > 0 && (
                <div className="sponsors-section" id="SponsorsSection">
                  <div className="container">
                    <div className="row align-items-center">
                      <div className="col-md-3 offset-md-0 col-12 text-center">
                        <p className="white-text bold-font page-title2 mb-1">
                          Our Sponsors
                        </p>
                      </div>
                      <div className="col-md-9 divided-column">
                        <ul
                          className="nav nav-pills nav-fill nav-justified ms-md-5 page-tabs2 scroll-brands"
                          id="myTab"
                          role="tablist"
                        >
                          <li className="nav-item m-1" role="presentation">
                            <button
                              className="nav-link active p-3 me-2"
                              id="section-tab1"
                              data-bs-toggle="tab"
                              data-bs-target="#all"
                              type="button"
                              role="tab"
                              aria-controls="all"
                              aria-selected="true"
                              onClick={() => setCat("")}
                            >
                              <p className="page-info1 mb-0">All</p>
                            </button>
                          </li>
                          {categories.map((category, index) => (
                            <li
                              className="nav-item m-1"
                              key={index}
                              role="presentation"
                            >
                              <button
                                className="nav-link p-3"
                                id="section-tab2"
                                data-bs-toggle="tab"
                                data-bs-target="#tab2"
                                type="button"
                                role="tab"
                                aria-controls="plan"
                                aria-selected="false"
                                onClick={() => setCat(category.name)}
                              >
                                <p className="page-info1 mb-0">
                                  {category.name}
                                </p>
                              </button>
                            </li>
                          ))}
                        </ul>
                        {sponsors.length > 0 && (
                          <div className="tab-content mt-4" id="myTabContent">
                            <div
                              className="tab-pane fade show active"
                              id="all"
                              role="tabpanel"
                              aria-labelledby="section-tab1"
                            >
                              <div className="scroll-brands">
                                {sponsors.map((sponsor, index) => (
                                  <div
                                    className="col-md-3 col-6 text-center"
                                    key={index}
                                  >
                                    <Link
                                      to={`/${router.id}/sponsors/${sponsor.id}`}
                                    >
                                      <img
                                        src={`https://api.frontiers.events/storage/${sponsor.logo}`}
                                        alt="Waveless"
                                        className="brand-img"
                                      />
                                    </Link>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {booths.length > 0 && (
                <div className="section-yaxis-space" id="BoothsSection">
                  <div className="container">
                    <div className="row mb-5">
                      <div className="col-md-12 text-center">
                        <p className="blue-text bold-font page-title1 lined-title">
                          Available Booths
                        </p>
                      </div>
                    </div>
                    <div className="row my-5">
                      <div className="col-md-6 offset-md-3">
                        <div className="input-group mb-3 search-bar">
                          <span className="input-group-text" id="basic-addon1">
                            <img
                              src="../assets/images/search.svg"
                              alt="Search Icon"
                              className="search-icon"
                            />
                          </span>
                          <input
                            type="text"
                            className="form-control regular-font"
                            placeholder="Search for booth..."
                            aria-label="attendant"
                            aria-describedby="basic-addon1"
                            onChange={(e) => setBoothSearch(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="cards-brands-slider">
                    {boothsArr.map((booth, index) => (
                      <Link key={index} to={`/${router.id}/booths/${booth.id}`}>
                        <div className="item" key={index}>
                          <div className="card page-card up-view">
                            <div className="card-body">
                              <a>
                                <div className="booth-card">
                                  <img
                                    src={`https://frontiers.o-projects.org/storage/${booth.logo}`}
                                    className="booth-image"
                                    alt="Waveless"
                                  />
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </Link>
                    ))}
                  </div>
                </div>
              )}
            </main>
            <footer>
              <div className="footer-section">
                <div className="container">
                  <div className="row mb-4">
                    <div className="col-md-12 text-center">
                      <a href="index.html">
                        <img
                          src="../assets/images/frontiers-logo.png"
                          className="frontiers-logo"
                          alt="Frontiers Logo"
                        />
                      </a>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4 offset-md-4 text-center">
                      {record?.facebook_link != "#" && (
                        <a
                          href={record.facebook_link}
                          className="me-5 dark-text"
                        >
                          <i className="fa-brands fa-facebook-square page-info1"></i>
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="footer-line2">
                <div className="container">
                  <div className="row">
                    <div className="col-md-12 text-center">
                      <p className="mb-0 white-text">
                        © Frontiers. All rights reserved 2022 - Made By
                        O-Projects
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </footer>
          </body>
        </>
      )}
    </>
  );
}

export default Home;
