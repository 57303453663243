//import "../styles/globals.css";
//import "./assets/css/style.css";
import { Provider, useSelector } from "react-redux";
import { persistor, store } from "./store/store";
//import Script from "next/script";
import { Routes, Route, Link } from "react-router-dom";
import Home from "./pages/Home";
import SessionSpeakers from "./pages/sessions/SessionSpeakers";
import Register from "./pages/Auth/Register";
import SignIn from "./pages/Auth/SignIn";
import Navbar from "./components/Navbar";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PurchaseTicket from "./pages/Event/PurchaseTicket";
import ForgetPass from "./pages/Auth/ForgetPass";
import EnterCode from "./pages/Auth/EnterCode";
import TicketForm from "./pages/Event/TicketForm";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getEventsDetails } from "./api/Events/actions";
import { checkUser } from "./api/Auth/actions";
import BoothDetails from "./pages/Booth/BoothDetails";
import SponsorsDetails from "./pages/Sponsors/SponsorsDetails";
import Sessons from "./pages/sessions/Sessions";
import MySessions from "./pages/sessions/MySessions";
import PurchaseTicketByPromo from "./pages/Event/PurchaseTicketByPromo";
import { useParams } from "react-router";
import SessionDetails from "./pages/sessions/SessionDetails";
import Announcement from "./pages/Announcement/Announcement";
import ResetPass from "./pages/Auth/ResetPass";
import { RouteGuard } from "./components/RouterGaurd";
import { PersistGate } from "redux-persist/integration/react";
import Routess from "./Routes";

function App({ Component, pageProps }) {
  const dispatch = useDispatch();
  const { isAuth, user } = useSelector((state) => state.user);

  useEffect(() => {
    //const id = localStorage.getItem("event_alias");

    //console.log(id,'appid')

    //dispatch(getEventsDetails({ alias: id }));
    dispatch(checkUser());
  }, []);

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Routess />
      </PersistGate>
    </Provider>
  );
}

export default App;
