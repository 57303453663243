import { createReducer } from "@reduxjs/toolkit";
import {
  DeleteSessionToMyAgenda,
  getAgendaDays,
  getAllSpeakers,
  getEventSession,
  getEventSessionbyDate,
  getMySessions,
  getSessionbyId,
  getSessionSpeakers,
  getWebinar,
} from "./actions";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const initialState = {
  Speakers: [
    {
      id: null,
      name: "",
      title: "",
      avatar: null,
      session_id: null,
      event_id: null,
      created_at: "",
      updated_at: "",
    },
  ],
  sessions: [],
  mysession: {},
  userSessions: [],
  Days: [],
  loading: false,
  webinar: {},
  total: 1,
};

export const sessionReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getSessionSpeakers.pending, (state) => {
      // state.errorpayload = true;
    })
    .addCase(getSessionSpeakers.fulfilled, (state, { payload }) => {
      state.Speakers = payload.list;
      state.total = payload.total;
      //state.errorpayload = false;
    })
    .addCase(getWebinar.fulfilled, (state, { payload }) => {
      state.webinar = payload.data;
    })
    .addCase(getSessionSpeakers.rejected, (state, { payload }) => {
      console.log("hi");
      //state.errorpayload = false;
    })
    .addCase(getAllSpeakers.pending, (state) => {
      // state.errorpayload = true;
    })
    .addCase(getAllSpeakers.fulfilled, (state, { payload }) => {
      state.Speakers = payload.list;
      state.total = payload.total;
      //state.errorpayload = false;
    })
    .addCase(getAllSpeakers.rejected, (state, { payload }) => {
      console.log("hi");
      //state.errorpayload = false;
    })
    .addCase(getEventSession.pending, (state) => {
      // state.errorpayload = true;
    })
    .addCase(getEventSession.fulfilled, (state, { payload }) => {
      console.log(payload.list, "payloadlist");
      // state.sessions = payload.list;

      //state.errorpayload = false;
    })
    .addCase(getEventSessionbyDate.fulfilled, (state, { payload }) => {
      console.log(payload.list, "payloadlist");
      state.sessions = payload.data;

      //state.errorpayload = false;
    })
    .addCase(getSessionbyId.fulfilled, (state, { payload }) => {
      console.log(payload.data, "payloadlist");
      if (payload.data) {
        state.mysession = payload.data;
        state.loading = false;
      }

      //state.errorpayload = false;
    })
    .addCase(getSessionbyId.rejected, (state, { payload }) => {
      state.loading = false;
      //state.errorpayload = false;
    })
    .addCase(getSessionbyId.pending, (state, { payload }) => {
      state.loading = true;
      //state.errorpayload = false;
    })
    .addCase(getAgendaDays.fulfilled, (state, { payload }) => {
      console.log(payload.list, "payloadlist");
      state.Days = payload.data;

      //state.errorpayload = false;
    })
    .addCase(getEventSession.rejected, (state, { payload }) => {
      console.log("hi");
      //state.errorpayload = false;
    })
    .addCase(getMySessions.pending, (state) => {
      // state.errorpayload = true;
    })
    .addCase(getMySessions.fulfilled, (state, { payload }) => {
      let arr = Object.keys(payload).map((key) => {
        return { ...payload[key] };
      });
      console.log(arr, "payloadlist");

      state.userSessions = arr;

      //state.errorpayload = false;
    })
    .addCase(getMySessions.rejected, (state, { payload }) => {
      console.log("hi");

      //state.errorpayload = false;
    })
    .addCase(DeleteSessionToMyAgenda.fulfilled, (state, { payload }) => {
      console.log("hi");
      //state.errorpayload = false;
    });
});

export default sessionReducer;
