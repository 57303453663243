import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
export const getEventsDetails = createAsyncThunk(
  "get/eventdetails",
  async (params) => {
    try {
      const response = await axios.get(
        "https://frontiers.o-projects.org/api/website/event/metadata",
        //"http://165.227.140.163/yallaeventsapi/public/api/website/event/metadata",
        { params }
      );
      console.log(response);
      localStorage.setItem("event_id", response.data.record.id);
      console.log(response.data, "response.data.record.alias");
      localStorage.setItem("event_alias", response.data?.record?.alias);
      return response.data;
    } catch (err) {
      //return rejectWithValue(err.response.data);
    }
  }
);
export const getEventsAgenda = createAsyncThunk(
  "get/eventAgenda",
  async (params) => {
    try {
      const response = await axios.get(
        "https://frontiers.o-projects.org/api/website/event/agenda",
        //"http://165.227.140.163/yallaeventsapi/public/api/website/event/agenda",
        { params }
      );
      console.log(response);
      return response.data;
    } catch (err) {
      //return rejectWithValue(err.response.data);
    }
  }
);

export const getAnnouncement = createAsyncThunk(
  "get/Announcement",
  async (params) => {
    try {
      const response = await axios.get(
        "https://frontiers.o-projects.org/api/website/event/announcmenets",
        //"http://165.227.140.163/yallaeventsapi/public/api/website/event/announcmenets",
        { params }
      );
      console.log(response);
      return response.data;
    } catch (err) {
      //return rejectWithValue(err.response.data);
    }
  }
);
