import React from 'react'
import { Audio } from "react-loader-spinner";

function Loader() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        margin: "auto",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Audio
        height="80"
        width="80"
        radius="9"
        color="#2B6EC8"
        ariaLabel="three-dots-loading"
        wrapperStyle
        wrapperClass
      />
    </div>
  );
}

export default Loader