import React, { useEffect } from "react";
import {
  AddSessionToMyAgenda,
  DeleteSessionToMyAgenda,
  getAgendaDays,
  getEventSession,
  getEventSessionbyDate,
  getMySessions,
} from "../../api/Sessions/actions";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import Loader from "../../components/Loader";
import { useState } from "react";
import Navbar from "../../components/Navbar";
import { getuserTickets } from "../../api/Auth/actions";
import { toast } from "react-toastify";
import Modal from "react-modal";
import {
  AddSlotToMyAgenda,
  DeleteBoothToMyAgenda,
  getBoothslots,
  getMybooths,
} from "../../api/Booths/actions";
function Slots() {
  const dispatch = useDispatch();
  const { record, agenda } = useSelector((state) => state.event);

  const { slots, loading, userBooths } = useSelector((state) => state.booths);
  const [signModal, setSignModal] = useState(false);

  const { user, userTicket } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const route = useParams();
  const [day, setDay] = useState(0);
  console.log(slots, "recordid");
  //   useEffect(() => {
  //     dispatch(getEventSession({ agenda_id: record?.id }));
  //     let token = localStorage.getItem("user-token");

  //     if (!token) {
  //       setSignModal(true);
  //     }
  //     if (record.id) {
  //       //dispatch(getEventSessionbyDate({ event_id: record?.id, date: "" }));
  //       let body = {
  //         mobileno: user?.mobileno,
  //       };
  //       dispatch(getuserTickets({ body, id: record.id }));
  //       dispatch(getAgendaDays(record?.id));
  //       dispatch(getMySessions({ event_id: record?.id }));
  //     }
  //   }, [record, user]);

  useEffect(() => {
    console.log(route.name, record.id);
    dispatch(getBoothslots({ name: route.name }));
    let token = localStorage.getItem("user-token");

    if (!token) {
      setSignModal(true);
    }
  }, []);
  useEffect(() => {
    let body = {
      mobileno: user?.mobileno,
    };
    dispatch(getuserTickets({ body, id: record.id }));

    dispatch(getMybooths({ event_id: record?.id }));
  }, [user, record]);
  const handleSchedule = (e, sessionId) => {
    e.preventDefault();
    e.stopPropagation();
    let token = localStorage.getItem("user-token");
    if (!token) {
      navigate("/" + route.id + "/register");
    }
    let index = null;
    index = userBooths.findIndex((x) => x.id === sessionId);
    if (index == -1) {
      if (userTicket.length > 0) {
        dispatch(AddSlotToMyAgenda({ id: sessionId, name: route.name }));
      } else {
        toast.error("Get your invitation first");
      }
    } else {
      dispatch(DeleteBoothToMyAgenda({ id: sessionId, name: route.name }));
    }
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      width: "23.188rem",
      height: "15.125rem",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };
  return (
    <div>
      {loading ? (
        <Loader></Loader>
      ) : (
        <>
          <Navbar></Navbar>
          <Modal
            isOpen={signModal}
            onRequestClose={() => {
              setSignModal(false);
            }}
            style={customStyles}
          >
            <div>
              <div
                className="flex-center"
                style={{
                  color: "#2B6EC8",
                  fontSize: "1rem",
                  fontWeight: "bold",
                }}
              >
                Please sign in or register to get your invitation
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "50px",
                }}
              >
                <div
                  class="form-btn page-info2 bold-font"
                  //disabled={error}
                  onClick={() => navigate("/" + record?.id + "/register")}
                >
                  Register
                </div>
              </div>
            </div>
          </Modal>
          <main>
            <div class="section-yaxis-space" style={{ marginTop: "2rem" }}>
              <div class="container">
                <div class="row">
                  <div class="col-md-12 text-center">
                    <p class="blue-text bold-font page-title1">Time Slots</p>
                  </div>
                </div>
                {/* <div class="row">
                  <div class="col-xl-6 offset-xl-3 col-md-8 offset-md-2 text-center">
                    <ul
                      class="nav nav-pills nav-fill justify-content-around sessions-tabs"
                      id="myTab"
                      role="tablist"
                    >
                      {Days?.map((day, index) => (
                        <li class="nav-item m-1" role="presentation">
                          <button
                            className={
                              day == index || index == 0
                                ? "nav-link active"
                                : "nav-link"
                            }
                            id="home-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#day1"
                            type="button"
                            role="tab"
                            aria-controls="home"
                            aria-selected="true"
                            onClick={() => setDay(index)}
                          >
                            <p class="black-font mb-0">{day.title}</p>
                            <p class="regular-font mb-0">{day.date}</p>
                          </button>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div> */}
                <div class="row">
                  {slots?.map((obj, index) => (
                    <div class="col-md-4 ">
                      <div class="tab-content mt-5" id="myTabContent">
                        <div
                          class="tab-pane fade show active"
                          id="day1"
                          role="tabpanel"
                          aria-labelledby="home-tab"
                          style={{
                            borderRadius: "5px",
                            background: "#f6f6f6",
                            width: "100%",
                          }}
                        >
                          <a>
                            <div
                              class="d-flex my-5 align-items-center flex-wrap flex-md-nowrap"
                              style={{
                                cursor: "pointer",
                                justifyContent: "center",
                              }}
                            >
                              {/* <div className="session-img-container"></div> */}
                              <div
                                class="mt-3 mt-md-0 "
                                style={{
                                  width: "100%",
                                  justifyContent: "center",
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                  padding: "2rem",
                                }}
                              >
                                <div class="d-flex align-items-center justify-content-between">
                                  <p class="blue-text black-font page-title3">
                                    slot number {index + 1}
                                  </p>
                                </div>

                                <p class="black-text regular-font page-info2">
                                  {obj.timeline}
                                </p>

                                <button
                                  class="overlay-btn text-uppercase mt-3 black-font"
                                  style={
                                    userBooths.findIndex(
                                      (x) => x.id === obj.id
                                    ) == -1
                                      ? {}
                                      : { background: "red" }
                                  }
                                  onClick={(e) => {
                                    handleSchedule(e, obj.id);
                                  }}
                                >
                                  {userBooths.findIndex(
                                    (x) => x.id === obj.id
                                  ) == -1
                                    ? "Reserve Slot"
                                    : "Remove from my Schedule "}
                                </button>
                              </div>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </main>
          <footer>
            <div class="footer-section">
              <div class="container">
                <div class="row mb-4">
                  <div class="col-md-12 text-center">
                    <a href="index.html">
                      <img
                        src="/assets/images/frontiers-logo.png"
                        class="frontiers-logo"
                        alt="Frontiers Logo"
                      />
                    </a>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4 offset-md-4 text-center">
                    {record?.facebook_link != "#" && (
                      <a href={record.facebook_link} className="me-5 dark-text">
                        <i className="fa-brands fa-facebook-square page-info1"></i>
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div class="footer-line2">
              <div class="container">
                <div class="row">
                  <div class="col-md-12 text-center">
                    <p class="mb-0 white-text">
                      © Frontiers. All rights reserved 2022 - Made By O-Projects
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </>
      )}
    </div>
  );
}

export default Slots;
