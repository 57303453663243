import { createReducer } from "@reduxjs/toolkit";
import { getPartners, getSponsorbyID, getSponsorCategories, getSponsors, getSponsorTeam } from "./actions";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const initialState = {
  sponsors: [
    {
      id: null,
      name: "",
      type: null,
      about: "",
      address: "",
      logo: "",
      image: "",
      video: "",
      website: "",
      facebook: "",
      instagram: null,
      youtube: "",
      event_id: null,
      user_id: null,
      created_at: "",
      updated_at: "",
      external_link: null,
      external_lable: "",
      gallery_images: [],
      gallery_videos: [],
    },
  ],
  sponsor:{},
  categories:[],
  loading:false
};

export const sponsorsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getSponsors.pending, (state) => {
      // state.errorpayload = true;
    })
    .addCase(getSponsors.fulfilled, (state, { payload }) => {
      state.sponsors = payload.list;
    })
    .addCase(getSponsors.rejected, (state, { payload }) => {
      console.log("hi");
      //state.errorpayload = false;
    })
    .addCase(getSponsorbyID.pending, (state) => {
      // state.errorpayload = true;
    })
    .addCase(getSponsorbyID.fulfilled, (state, { payload }) => {
      state.sponsor = payload.record;
      state.loading = true;
    })
    .addCase(getSponsorbyID.rejected, (state, { payload }) => {
      console.log("hi");
      //state.errorpayload = false;
    })
    .addCase(getSponsorCategories.pending, (state) => {
      // state.errorpayload = true;
      state.loading = false;

    })
    .addCase(getSponsorCategories.fulfilled, (state, { payload }) => {
      state.categories = payload.cats;
      state.loading = true;
    })
    .addCase(getSponsorTeam.fulfilled, (state, { payload }) => {
      state.team = payload.list;
      state.loading=true;
    })
    .addCase(getSponsorTeam.pending, (state, { payload }) => {
      state.loading=false
    })
    .addCase(getSponsorCategories.rejected, (state, { payload }) => {
      console.log("hi");
      state.loading = true;

      //state.errorpayload = false;
    });
});

export default sponsorsReducer;
