import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getSponsorbyID } from "../../api/Sponsers/actions";
import { useState } from "react";
import { getAnnouncement } from "../../api/Events/actions";
import Navbar from "../../components/Navbar";

function Announcement() {
  const { id } = useParams();
  const { record, announcements, loading } = useSelector(
    (state) => state.event
  );

  const dispatch = useDispatch();
  useEffect(() => {
    if (record?.id) {
      dispatch(getAnnouncement({ event_id: record.id }));
      window.scrollTo(0, 0);
    }
  }, [record]);
  console.log(announcements, "anno");
  return (
    <div>
      {!loading && (
        <>
          <Navbar></Navbar>
          <main>
            <div class="section-yaxis-space">
              <div class="container">
                <div class="row">
                  <div class="col-xl-4 offset-xl-4 col-md-6 offset-md-3 text-center">
                    <ul
                      class="nav nav-pills nav-fill justify-content-around sessions-tabs"
                      id="myTab"
                      role="tablist"
                    >
                      {/* <li class="nav-item m-1" role="presentation">
                        <button
                          class="nav-link  p-3 me-2"
                          id="attendees-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#attendees"
                          type="button"
                          role="tab"
                          aria-controls="attendees"
                          aria-selected="true"
                        >
                          <p class="black-font text-uppercase mb-0">
                            Attendees
                          </p>
                        </button>
                      </li> */}
                      <li class="nav-item m-1" role="presentation">
                        <button
                          class="nav-link p-3 active"
                          id="announcements-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#announcements"
                          type="button"
                          role="tab"
                          aria-controls="announcements"
                          aria-selected="false"
                        >
                          <p class="black-font text-uppercase mb-0">
                            Announcements
                          </p>
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-10 offset-md-1">
                    <div class="tab-content mt-5" id="myTabContent">
                      <div
                        class="tab-pane fade"
                        id="attendees"
                        role="tabpanel"
                        aria-labelledby="attendees-tab"
                      >
                        <div class="row my-5">
                          <div class="col-md-8 offset-md-2">
                            <div class="input-group mb-3 search-bar">
                              <span class="input-group-text" id="basic-addon1">
                                <img
                                  src="../assets/images/search.svg"
                                  alt="Search Icon"
                                  class="search-icon"
                                />
                              </span>
                              <input
                                type="text"
                                class="form-control regular-font"
                                placeholder="Search for attendant..."
                                aria-label="Username"
                                aria-describedby="basic-addon1"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-8 offset-md-2">
                            <div class="d-flex justify-content-between">
                              <p class="regular-font black-text page-info3">
                                <img
                                  src="../assets/images/user1.jpg"
                                  class="user-img me-3"
                                />{" "}
                                Nicolas Sanders
                              </p>
                              <div>
                                <i class="fa-solid fa-briefcase side-icon1 me-3"></i>
                                <img
                                  src="../assets/images/comments-o.svg"
                                  class="side-icon"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        class="tab-pane fade show active"
                        id="announcements"
                        role="tabpanel"
                        aria-labelledby="announcements-tab"
                      >
                        <div class="row">
                          <div class="col-md-10 offset-md-1">
                            <div class="card-head-section">
                              <p class="white-text bold-font page-info1 mb-0 text-center text-uppercase">
                                Event Announcements
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-10 offset-md-1">
                          <div class="card-body-list">
                            {announcements?.map((ann, index) => (
                              <div class="item-line py-4" key={index}>
                                <div class="row">
                                  <div class="col-md-2 text-center">
                                    <img
                                      src={`${ann.image}`}
                                      class="side-round-img"
                                    />
                                  </div>
                                  <div class="col-md-10">
                                    <p class="dark-text regular-font page-info2">
                                      {ann.content}
                                    </p>
                                  </div>
                                  <div class="col text-end">
                                    <p class="side-note bold-font page-info4">
                                      {new Date(ann.created_at).toLocaleString(
                                        "en-US",
                                        {
                                          day: "numeric",
                                          month: "2-digit",
                                          timeZone: "UTC",
                                          hour12: true,
                                          hour: "numeric",
                                          minute: "numeric",
                                        }
                                      )}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
          <footer>
            <div class="footer-section">
              <div class="container">
                <div class="row mb-4">
                  <div class="col-md-12 text-center">
                    <a href="index.html">
                      <img
                        src="../assets/images/frontiers-logo.png"
                        class="frontiers-logo"
                        alt="Frontiers Logo"
                      />
                    </a>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4 offset-md-4 text-center">
                    {record?.facebook_link != "#" && (
                      <a href={record.facebook_link} className="me-5 dark-text">
                        <i className="fa-brands fa-facebook-square page-info1"></i>
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div class="footer-line2">
              <div class="container">
                <div class="row">
                  <div class="col-md-12 text-center">
                    <p class="mb-0 white-text">
                      © Frontiers. All rights reserved 2022 - Made By O-Projects
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </>
      )}
    </div>
  );
}

export default Announcement;
