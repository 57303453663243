import { configureStore } from "@reduxjs/toolkit";
import { combineReducers, createStore ,compose,applyMiddleware} from "redux";
import Thunk from "redux-thunk";
import  eventReducer  from "../api/Events/reducers";
import  sessionReducer  from "../api/Sessions/reducers";
import  userReducer  from "../api/Auth/reducers";
import  ticketReducer  from "../api/Tickets/reducers";
import  partnerReducer  from "../api/Partners/reducers";
import  sponsorsReducer  from "../api/Sponsers/reducers";
import  boothsReducer  from "../api/Booths/reducers";
import { persistStore, persistReducer}  from 'redux-persist'
import storage from 'redux-persist/lib/storage' 
const persistConfig = {
  key: 'root',
  storage,
  whitelist:['event']
}
const reducers = combineReducers({
  event: eventReducer,
  session: sessionReducer,
  user: userReducer,
  ticket: ticketReducer,
  partner: partnerReducer,
  sponsors: sponsorsReducer,
  booths: boothsReducer,
});
let persistedReducer=persistReducer(persistConfig,reducers)
 const store = createStore(
  persistedReducer,
  compose(applyMiddleware(Thunk)),
);
const persistor=persistStore(store);
export {store,persistor}
