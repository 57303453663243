import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { ToastContainer, toast, cssTransition } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export const purchaseTicket = createAsyncThunk("buy/ticket", async (body) => {
  try {
    let token = localStorage.getItem("user-token");
    console.log(body, "tokenpurchase");
    const response = await axios.post(
      "https://frontiers.o-projects.org/api/event/order/create/guest",
      body,
      {
        headers: { Authorization: `Bearer${token}` },
      }
    );
    console.log(response);
    window.location.href = `${response.data.paymentLink}`;
    return response.data;
  } catch (err) {
    //return rejectWithValue(err.response.data);
    let error = Object.values(err.response.data.errors);
    console.log(error[0][0], "error");
    toast.error(error[0][0]);
  }
});
export const getCountries = createAsyncThunk("get/countries", async () => {
  try {
    const response = await axios.get(
      "https://ajayakv-rest-countries-v1.p.rapidapi.com/rest/v1/all",

      {
        headers: {
          "X-RapidAPI-Key": "SIGN-UP-FOR-KEY",
          "X-RapidAPI-Host": "ajayakv-rest-countries-v1.p.rapidapi.com",
        },
      }
    );

    return response.data;
  } catch (error) {
    //return rejectWithValue(err.response.data);

    console.log(error, "error");
    toast.error(error);
  }
});
export const getSelectedTicket = createAsyncThunk(
  "get/selected-ticket",
  async ({ body, nav, alias, payment }) => {
    console.log(body, payment, "getSelectedTicket");
    nav("/" + alias + "/ticket-info");
    return [body, payment];
  }
);
export const getTicketCode = createAsyncThunk(
  "get/ticketCode",
  async ({ params, nav, alias }) => {
    console.log(params, "paraams");
    try {
      const response = await axios.get(
        "https://frontiers.o-projects.org/api/website/event/codes/validate",
        { params }
      );
      if (response.data.isFound) {
        console.log(response.data.isFound);
        nav("/" + alias + "/ticket-info");
        return [response.data, params.code];
      }
    } catch (err) {
      //return rejectWithValue(err.response.data);
    }
  }
);
