import { createReducer } from "@reduxjs/toolkit";
import { getPartners } from "./actions";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const initialState = {
  partners: [
    {
      id: null,
      name: "",
      type: null,
      about: "",
      address: "",
      logo: "",
      image: "",
      video: "",
      website: "",
      facebook: "",
      instagram: null,
      youtube: "",
      event_id: null,
      user_id: null,
      created_at: "",
      updated_at: "",
      external_link: null,
      external_lable: "",
      gallery_images: [],
      gallery_videos: [],
    },
  ],
};

export const partnerReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getPartners.pending, (state) => {
      // state.errorpayload = true;
    })
    .addCase(getPartners.fulfilled, (state, { payload }) => {
      state.partners = payload.list;
    })
    .addCase(getPartners.rejected, (state, { payload }) => {
      console.log("hi");
      //state.errorpayload = false;
    });
});

export default partnerReducer;
