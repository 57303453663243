import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import group31 from "../../assets/images/Group931.jpg";
import { useSelector, useDispatch } from "react-redux";
import { forgetPass } from "../../api/Auth/actions";
import { Routes, Route, useNavigate } from "react-router-dom";
import Navbar from "../../components/Navbar";

function ForgetPass() {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [error, setError] = useState(false);
  const { record } = useSelector((state) => state.event);
  
  const router=useParams();
  const navigate = useNavigate();
  const ChangePassword = () => {
    if (email) {
      const formdata = new FormData();
      formdata.append("email", email);
      formdata.append("event_id", record?.id);
      
      dispatch(forgetPass({ body: formdata, nav: navigate ,alias:router.id}));
    } else {
      setError(true);
    }
  };
  return (
    <div>
    <Navbar></Navbar>

      <main>
        <div class="container-fluid">
          <div class="row align-items-center">
            <div class="col-md-6 p-0">
              <div class="position-relative  blue-header">
                <img src={group31} class="side-img" />
                <div class="side-vertical-tabs">
                  <p class="side-menu-title text-center text-md-end pe-4">
                    Forgot Password
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="px-5">
                <p class="page-title5 blue-text bold-font">Forgot Password</p>
                
                <div class="page-form">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="mb-3">
                        <label for="mail" class="form-label">
                          Email Address
                        </label>
                        <input
                          style={error ? { border: "1px solid red" } : {}}
                          type="email"
                          class="form-control"
                          id="mail"
                          placeholder=""
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row mt-5">
                    <div class="col-md-6">
                      <div class="mb-3">
                        <a>
                          <button
                            class="form-btn2 page-info2 bold-font"
                            onClick={ChangePassword}
                          >
                            Change Password
                          </button>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      <footer>
        <div class="footer-section">
          <div class="container">
            <div class="row mb-4">
              <div class="col-md-12 text-center">
                <a href="index.html">
                  <img
                    src="../assets/images/frontiers-logo.png"
                    class="frontiers-logo"
                    alt="Frontiers Logo"
                  />
                </a>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4 offset-md-4 text-center">
                <a href="" class="me-5 dark-text">
                  <i class="fa-brands fa-facebook-square page-info1"></i>
                </a>
                <a href="" class="me-5 dark-text">
                  <i class="fa-brands fa-instagram page-info1"></i>
                </a>
                <a href="" class="me-5 dark-text">
                  <i class="fa-brands fa-twitter page-info1"></i>
                </a>
                <a href="" class="dark-text">
                  <i class="fa-brands fa-linkedin page-info1"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="footer-line2">
          <div class="container">
            <div class="row">
              <div class="col-md-12 text-center">
                <p class="mb-0 white-text">
                  © Frontiers. All rights reserved 2022 - Made By O-Projects
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default ForgetPass;
