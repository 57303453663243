import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
export const getSessionSpeakers = createAsyncThunk(
  "get/sessionSpeakers",
  async (params) => {
    try {
      const response = await axios.get(
        "https://frontiers.o-projects.org/api/website/event/speakers",
        { params }
      );
      console.log(response);
      return response.data;
    } catch (err) {
      //return rejectWithValue(err.response.data);
    }
  }
);
export const getEventSession = createAsyncThunk(
  "get/EventSession",
  async (params) => {
    try {
      const response = await axios.get(
        "https://frontiers.o-projects.org/api/website/event/sessions",
        { params }
      );
      console.log(response);
      return response.data;
    } catch (err) {
      //return rejectWithValue(err.response.data);
    }
  }
);
export const getEventSessionbyDate = createAsyncThunk(
  "get/EventSessionbyDate",
  async (params) => {
    try {
      const response = await axios.get(
        //"http://165.227.140.163/yallaeventsapi/public/api/website/event/get-session-details-by-day",
        "https://frontiers.o-projects.org/api/website/event/get-session-details-by-day",
        { params }
      );

      console.log(response);
      return response.data;
    } catch (err) {
      //return rejectWithValue(err.response.data);
    }
  }
);
export const getSessionbyId = createAsyncThunk(
  "get/sessionbyid",
  async (params) => {
    try {
      const response = await axios.get(
        //"http://165.227.140.163/yallaeventsapi/public/api/website/event/session-details",
        "https://frontiers.o-projects.org/api/website/event/session-details",
        { params }
      );

      console.log(response);
      return response.data;
    } catch (err) {
      //return rejectWithValue(err.response.data);
    }
  }
);
export const getAgendaDays = createAsyncThunk(
  "get/Agendadays",
  async (eventid) => {
    try {
      const response = await axios.get(
        // "http://165.227.140.163/yallaeventsapi/public/api/website/event/get-days?event_id=" +
        //   eventid +
        //   ""
        "https://frontiers.o-projects.org/api/website/event/get-days?event_id=" +
          eventid +
          ""
      );
      console.log(response);
      return response.data;
    } catch (err) {
      //return rejectWithValue(err.response.data);
    }
  }
);
export const getMySessions = createAsyncThunk(
  "get/MySession",
  async (params) => {
    try {
      let token = localStorage.getItem("user-token");
      console.log(params, "usertoken");
      var headers = {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };
      const response = await axios.get(
        "https://frontiers.o-projects.org/api/event/myagenda",
        { params, headers }
      );
      console.log(response);
      return response.data;
    } catch (err) {
      //return rejectWithValue(err.response.data);
    }
  }
);
export const getWebinar = createAsyncThunk(
  "get/webinar",
  async (webinar_id, { dispatch }) => {
    try {
      let token = localStorage.getItem("user-token");
      const response = await axios({
        method: "get",
        url: `https://frontiers.o-projects.org/api/event/sessions/webinar/${webinar_id}/join`,
        headers: { Authorization: `Bearer ${token}` },
      });

      return response.data;
    } catch (err) {
      //return rejectWithValue(err.response.data);
      console.log(err);
      toast.error(err.response.data.message);
    }
  }
);
export const AddSessionToMyAgenda = createAsyncThunk(
  "add/Session",
  async ({ params, event_id }, { dispatch }) => {
    try {
      console.log(params, "hi");
      let token = localStorage.getItem("user-token");

      const response = await axios({
        method: "post",
        url: `https://frontiers.o-projects.org/api/event/myagenda/add-session?session_id=${params}`,
        headers: { Authorization: `Bearer ${token}` },
      });

      console.log(response);
      toast.success("Session Added Successfuly");
      dispatch(getMySessions({ event_id }));

      return response.data;
    } catch (err) {
      //return rejectWithValue(err.response.data);
      console.log(err);
      toast.error(err.response.data.message);
    }
  }
);
export const DeleteSessionToMyAgenda = createAsyncThunk(
  "delete/mysession",
  async ({ params, event_id }, { dispatch }) => {
    try {
      let token = localStorage.getItem("user-token");

      const response = await axios({
        method: "delete",
        url: `https://frontiers.o-projects.org/api/event/myagenda/delete-session?session_id=${params}`,
        headers: { Authorization: `Bearer ${token}` },
      });

      console.log(response);
      toast.success("Session Deleted Successfuly");
      dispatch(getMySessions({ event_id: event_id }));
      return response.data;
    } catch (err) {
      //return rejectWithValue(err.response.data);
      toast.error(err.response.data.error);
    }
  }
);
export const getAllSpeakers = createAsyncThunk(
  "get/allSpeakers",
  async (params) => {
    try {
      const response = await axios.get(
        "https://frontiers.o-projects.org/api/website/event/allspeakers",
        { params }
      );
      console.log(response);
      return response.data;
    } catch (err) {
      //return rejectWithValue(err.response.data);
    }
  }
);
