import React, { useEffect } from "react";
import {
  AddSessionToMyAgenda,
  DeleteSessionToMyAgenda,
  getEventSession,
  getMySessions,
} from "../../api/Sessions/actions";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import Loader from "../../components/Loader";
import { getEventsDetails } from "../../api/Events/actions";
import Navbar from "../../components/Navbar";

function MySessions() {
  const dispatch = useDispatch();
  const { record, agenda } = useSelector((state) => state.event);
  const { userSessions, loading } = useSelector((state) => state.session);
  const navigate = useNavigate();
  const route = useParams();
  console.log(record, "recordid");
  useEffect(() => {
    if (record.id) {
      let token = localStorage.getItem("user-token");
      if (token) {
        dispatch(getMySessions({ event_id: record?.id }));
      }
    }
  }, [record]);
  const handleSchedule = (e, sessionId) => {
    e.preventDefault();
    e.stopPropagation();
    let token = localStorage.getItem("user-token");
    if (!token) {
      navigate("/register");
    }
    let index = null;
    index = userSessions.findIndex((x) => x.id === sessionId);
    if (index == -1) {
      dispatch(
        AddSessionToMyAgenda({ params: sessionId, event_id: record.id })
      );
    } else {
      dispatch(
        DeleteSessionToMyAgenda({ params: sessionId, event_id: record.id })
      );
    }
  };
  console.log(userSessions, "sessions");
  return (
    <div>
      {loading ? (
        <Loader></Loader>
      ) : (
        <>
          <Navbar></Navbar>
          <main>
            <div class="section-yaxis-space">
              <div class="container">
                <div class="row">
                  <div class="col-md-12 text-center">
                    <p class="blue-text bold-font page-title1">All Sessions</p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-10 offset-md-1">
                    <div class="tab-content mt-5" id="myTabContent">
                      <div
                        class="tab-pane fade show active"
                        id="day1"
                        role="tabpanel"
                        aria-labelledby="home-tab"
                      >
                        {userSessions.map((obj, index) => (
                          <a>
                            <div
                              class="d-flex my-5 align-items-center flex-wrap flex-md-nowrap"
                              onClick={(e) => {
                                navigate(`/${route.id}/sessions/${obj.id}`);
                              }}
                            >
                              <div style={{ width: "31%" }}>
                                <img
                                  src={`https://frontiers.o-projects.org/storage/${obj.logo}`}
                                  class="session-img"
                                  alt="Session Image"
                                />
                              </div>
                              <div
                                class="mt-3 mt-md-0 "
                                style={{ width: "90%" }}
                              >
                                <div class="d-flex align-items-center justify-content-between">
                                  <p class="blue-text black-font page-title3">
                                    {obj.title}
                                  </p>
                                  <p class="light-blue-text black-font  page-info2">
                                    {obj.start_time}-{obj.end_time}
                                  </p>
                                </div>

                                <p
                                  class="black-text regular-font page-info2"
                                  dangerouslySetInnerHTML={{
                                    __html: obj.description,
                                  }}
                                ></p>
                                {/* <button
                                  class="overlay-btn text-uppercase mt-3 black-font"
                                  style={
                                    userSessions.findIndex(
                                      (x) => x.id === obj.id
                                    ) == -1
                                      ? {}
                                      : { background: "red" }
                                  }
                                  onClick={(e) => handleSchedule(e, obj.id)}
                                >
                                  {userSessions.findIndex(
                                    (x) => x.id === obj.id
                                  ) == -1
                                    ? "Reserve Session"
                                    : "Remove from my Schedule "}
                                </button> */}
                              </div>
                            </div>
                          </a>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
          <footer>
            <div class="footer-section">
              <div class="container">
                <div class="row mb-4">
                  <div class="col-md-12 text-center">
                    <a href="index.html">
                      <img
                        src="../assets/images/frontiers-logo.png"
                        class="frontiers-logo"
                        alt="Frontiers Logo"
                      />
                    </a>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4 offset-md-4 text-center">
                    {record?.facebook_link != "#" && (
                      <a href={record.facebook_link} className="me-5 dark-text">
                        <i className="fa-brands fa-facebook-square page-info1"></i>
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div class="footer-line2">
              <div class="container">
                <div class="row">
                  <div class="col-md-12 text-center">
                    <p class="mb-0 white-text">
                      © Frontiers. All rights reserved 2022 - Made By O-Projects
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </>
      )}
    </div>
  );
}

export default MySessions;
