import React, { useEffect, useState } from "react";
import { Route, Router, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getSelectedTicket } from "../../api/Tickets/actions";
import group31 from "../../assets/images/Group931.jpg";

function PurchaseTicketByPromo() {
  const dispatch = useDispatch();
  const { isAuth } = useSelector((state) => state.user);
  const { selectedTicket, ticketTypes } = useSelector((state) => state.ticket);
  const navigate = useNavigate();
  useEffect(() => {
    let token1 = localStorage.getItem("user-info");
    if (!token1) {
      navigate("/register");
    }
  }, [isAuth]);
  const selectTicket = (ticket) => {
    console.log(ticket, "ticket");
    dispatch(getSelectedTicket({ body: ticket, nav: navigate }));
    console.log(selectedTicket);
  };
  console.log(ticketTypes, "tickettypes");
  return (
    isAuth && (
      <div>
        <main>
          <div class="container-fluid">
            <div class="row align-items-center">
              <div class="col-md-6 p-0">
                <div class="position-relative blue-header">
                  <img src={group31} class="side-img" />
                  <div class="side-vertical-tabs">
                    <p class="side-menu-title text-center text-md-end pe-4">
                      Buy Ticket
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-6 half-side-page">
                <div class="px-5">
                  <p class="page-title5 blue-text bold-font mb-5">
                    Choose Invitation Type
                  </p>
                  {ticketTypes?.map((type, index) => (
                    <div>
                      {type.soldquantity != type.quantity && (
                        <div
                          class="ticket-card mb-3"
                          key={index}
                          onClick={() => selectTicket(type)}
                        >
                          <a>
                            <div class="d-flex justify-content-between">
                              <p class="black-text bold-font page-info1">
                                {type.name}
                              </p>
                              <p class="blue-text bold-font page-info1 price-text">
                                {type.price}
                              </p>
                            </div>
                            <p class="black-text regular-font page-info4">
                              {type.description}
                            </p>
                          </a>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
                <div class="row mt-5">
                  <div class="col-md-6"></div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    )
  );
}

export default PurchaseTicketByPromo;
