import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
export const getPartners = createAsyncThunk("get/partners", async (params) => {
  try {
    const response = await axios.get(
      "https://frontiers.o-projects.org/api/website/event/partners",
      { params }
    );
    console.log(response);
    return response.data;
  } catch (err) {
    //return rejectWithValue(err.response.data);
  }
});
