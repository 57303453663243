import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { ToastContainer, toast, cssTransition } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export const userRegister = createAsyncThunk(
  "user/register",
  async ({ body, nav, alias }) => {
    try {
      const response = await axios.post(
        "https://frontiers.o-projects.org/api/customer/register",
        body
      );
      console.log(response, "res1");
      if (response.status == 200 || response.status == 201) {
        localStorage.setItem("user-token", response.data.token);
        localStorage.setItem("user-info", JSON.stringify(response.data.user));
        toast.success("You have been registerd successfully");
        //alert(nav(-1));
        nav(-1);
        return response.data.user;
      } else {
        console.log("hi");
      }
    } catch (e) {
      //alert();
      let error = Object.values(e.response.data);

      toast.error(error[0][0]);
    }
  }
);
export const userLogin = createAsyncThunk(
  "user/Login",
  async ({ body, nav, alias }) => {
    try {
      const response = await axios.post(
        "https://frontiers.o-projects.org/api/customer/login",
        body
      );
      console.log(response, "res");
      if (response.status == 200 || response.status == 201) {
        localStorage.setItem("user-token", response.data.token);
        localStorage.setItem("user-info", JSON.stringify(response.data.user));
        nav(-1);

        //nav("/" + alias + "");
        return response.data.user;
      } else {
        console.log("hi");
      }
    } catch (e) {
      //alert();
      toast.error(e.response.data.error);
    }
  }
);
export const forgetPass = createAsyncThunk(
  "forget/pass",
  async ({ body, nav, alias }) => {
    try {
      const response = await axios.post(
        "https://frontiers.o-projects.org/api/password/forget",
        body
      );

      console.log(response, "res");
      if (
        (response.status == 200 || response.status == 201) &&
        response.data.status != "User Not Found"
      ) {
        console.log("/Enter-Code");
        nav("/" + alias + "/Enter-Code");
        return body;
      } else {
        toast.error("User Not Found");
      }
    } catch (e) {
      //alert();
      toast.error(e.response.data.error);
      console.log("e", e);
    }
  }
);
export const getuserTickets = createAsyncThunk(
  "get/usertickets",
  async ({ body, id }) => {
    try {
      let token = localStorage.getItem("user-token");
      var headers = {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };
      const response = await axios({
        url:
          "https://frontiers.o-projects.org/api/event/tickets/byUser/" +
          id +
          "",
        method: "post",
        data: body,
        headers: { Authorization: `Bearer ${token}` },
      });
      console.log(response, "res");
      if (response.status == 200 || response.status == 201) {
        return response.data;
      } else {
        //console.log("hi");
      }
    } catch (e) {}
  }
);
export const resetPass = createAsyncThunk(
  "reset/pass",
  async ({ body, nav, alias }) => {
    try {
      const response = await axios.post(
        "https://frontiers.o-projects.org/api/password/reset",
        body
      );
      console.log(response, "res");
      if (response.status == 200 || response.status == 201) {
        nav("/" + alias + "/signin");
        return body;
      } else {
        //console.log("hi");
      }
    } catch (e) {
      //alert();
      toast.error(e.response.data.error);
      console.log("e", e);
    }
  }
);
export const sendCode = createAsyncThunk(
  "send/code",
  async ({ body, nav, alias }) => {
    try {
      const response = await axios.post(
        "https://frontiers.o-projects.org/api/password/code/check",
        body
      );
      let code = body.get("code");
      console.log(response, "res");
      if (response.status == 200 || response.status == 201) {
        nav("/" + alias + "/reset-password");
        return code;
      } else {
        //console.log("hi");
      }
    } catch (e) {
      //alert();
      toast.error(e.response.data.error);
      console.log("e", e);
    }
  }
);
export const checkUser = createAsyncThunk("check/user", async () => {
  let token1 = localStorage.getItem("user-token");
  let token2 = JSON.parse(localStorage.getItem("user-info"));
  console.log(token1, token2, "token1");

  if (token1 && token2) {
    return [token2, true];
  }
  return [null, false];
});
export const logOut = createAsyncThunk(
  "logout/user",
  async ({ nav, alias }) => {
    localStorage.removeItem("user-info");
    localStorage.removeItem("user-token");
    nav("/" + alias + "");
  }
);
