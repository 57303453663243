import { createReducer } from "@reduxjs/toolkit";
import { getEventsDetails, getEventsAgenda, getAnnouncement } from "./actions";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const initialState = {
  record: {
    id: null,
    name: "",
    alias: "",
    description: "",
    location: "",
    gpslocation: "",
    logo: null,
    banner: null,
    startdate: "",
    enddate: "",
    manualqrcodes: null,
    published: "",
    company_id: "",
    created_at: "",
    updated_at: "",
    map: null,
    catalogue_link: null,
    sections: [
      {
        id: null,
        name: "",
        type: null,
        title: "",
        title_ar: null,
        description: null,
        description_ar: null,
        extra: null,
        event_id: null,
        sectionorder: null,
        created_at: "",
        updated_at: "",
      },
    ],
  },
  agenda: [
    {
      id: null,
      order: null,
      title: "",
      description: "",
      event_id: null,
      booth_id: null,
      user_id: null,
      date: "",
      created_at: "",
      updated_at: "",
      sessions: [],
    },
  ],
  total: null,
  ticketTypes: [
    {
      id: null,
      name: "",
      name_ar: "",
      description: "",
      description_ar: "",
      type: null,
      servicefees_type: null,
      price: null,
      price_override: null,
      quantity: null,
      soldquantity: null,
      needapproval: 0,
      isprivate: 0,
      min: 1,
      max: 1,
      startduration: "",
      endduration: "",
      paymentdeadlinetype: null,
      paymentdeadline_days: null,
      infocollect_type: 1,
      event_id: null,
      fallback_ticket_id: null,
      created_at: "",
      updated_at: "",
    },
  ],
  catalogue:'',
  announcements:[],
  loading:false,
};

export const eventReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getEventsDetails.pending, (state) => {
      // state.errorpayload = true;
      state.loading = true;
    })
    .addCase(getEventsDetails.fulfilled, (state, { payload }) => {
      state.record = payload.record;
      state.ticketTypes = payload.ticketTypes;
      state.loading = false;
    })
    .addCase(getAnnouncement.fulfilled, (state, { payload }) => {
      state.announcements = payload.data;
      state.loading = false;

    })
    .addCase(getEventsDetails.rejected, (state, { payload }) => {
      console.log("hi");
      state.loading = false;

      //state.errorpayload = false;
    })
    .addCase(getEventsAgenda.pending, (state) => {
      // state.errorpayload = true;
    })
    .addCase(getEventsAgenda.fulfilled, (state, { payload }) => {
      state.agenda = payload.list;
      state.total = payload.total;
      //state.errorpayload = false;
    })
    .addCase(getEventsAgenda.rejected, (state, { payload }) => {
      console.log("hi");
      //state.errorpayload = false;
    });
});

export default eventReducer;
