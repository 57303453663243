import React, { useState } from "react";
import group31 from "../../assets/images/Group931.jpg";
import { useSelector, useDispatch } from "react-redux";
import { sendCode } from "../../api/Auth/actions";
import { useNavigate, useParams } from "react-router-dom";
import Navbar from "../../components/Navbar";
function EnterCode() {
const [code,setCode]=useState(['','','',''])
const dispatch=useDispatch()
const param=useParams();
const navigate = useNavigate();
console.log(code,'code')
const handleCode=()=>{
  

  let c =code.join('')
  if(c)
  {
    let form =new FormData()
      form.append('code',c)
      form.append('_method',"post")
    dispatch(sendCode({body:form,nav:navigate,alias:param.id}))

  }
}
  return (
    <div>
    <Navbar></Navbar>
      <main>
        <div class="container-fluid">
          <div class="row align-items-center">
            <div class="col-md-6 p-0">
              <div class="position-relative blue-header">
                <img src={group31} class="side-img" />
                <div class="side-vertical-tabs">
                  <p class="side-menu-title text-center text-md-end pe-4">
                    Forgot Password
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="px-5">
                <p class="page-title5 blue-text bold-font">Forgot Password</p>
                <p class="faded-black-text regular-font page-info2">
                  A code is sent to the submitted email address
                </p>
                <div class="page-form">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="mb-3 d-flex align-items-around">
                        <input
                          type="text"
                          class="form-control m-1 code-input"
                          id="code1"
                          placeholder=""
                          onChange={(e)=>{
                            let c=[...code]
                            c[0]=e.target.value
                            setCode(c)}}
                        />
                        <input
                          type="text"
                          class="form-control m-1 code-input"
                          id="code2"
                          placeholder=""
                          
                          onChange={(e)=>{
                            let c=[...code]
                            c[1]=e.target.value
                            setCode(c)}}
                        />
                        <input
                          type="text"
                          class="form-control m-1 code-input"
                          id="code3"
                          placeholder=""
                          onChange={(e)=>{
                            let c=[...code]
                            c[2]=e.target.value
                            setCode(c)}}
                        />
                        <input
                          type="text"
                          class="form-control m-1 code-input"
                          id="code4"
                          placeholder=""
                          onChange={(e)=>{
                            let c=[...code]
                            c[3]=e.target.value
                            setCode(c)}}
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row mt-5">
                    <div class="col-md-6">
                    <a>
                          <button
                            class="form-btn2 page-info2 bold-font"
                            onClick={handleCode}
                          >
                            Change Password
                          </button>
                        </a>
                      <a
                        href="forgot-password.html"
                        class="blue-text bold-font page-link1"
                      >
                        Didn't Receive code? Resend
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      <footer>
        <div class="footer-section">
          <div class="container">
            <div class="row mb-4">
              <div class="col-md-12 text-center">
                <a>
                  <img
                    src="../assets/images/frontiers-logo.png"
                    class="frontiers-logo"
                    alt="Frontiers Logo"
                  />
                </a>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4 offset-md-4 text-center">
                <a href="" class="me-5 dark-text">
                  <i class="fa-brands fa-facebook-square page-info1"></i>
                </a>
                <a href="" class="me-5 dark-text">
                  <i class="fa-brands fa-instagram page-info1"></i>
                </a>
                <a href="" class="me-5 dark-text">
                  <i class="fa-brands fa-twitter page-info1"></i>
                </a>
                <a href="" class="dark-text">
                  <i class="fa-brands fa-linkedin page-info1"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="footer-line2">
          <div class="container">
            <div class="row">
              <div class="col-md-12 text-center">
                <p class="mb-0 white-text">
                  © Frontiers. All rights reserved 2022 - Made By O-Projects
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default EnterCode;
