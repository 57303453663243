import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { getEventsDetails } from "../api/Events/actions";
import Loader from "./Loader";

export { RouteGuard };


function RouteGuard({ children }) {
  
 
  const dispatch = useDispatch();
  const  router = useParams();
  const { record, agenda,loading,catalogue } = useSelector((state) => state.event);
  const location = useLocation();
//alert(record.id)

  return (
    <>
      {loading ? (
        <Loader/>
      ) : (
        children
      )}
    </>
  );
}
