import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getBoothbyID } from "../../api/Booths/actions";
import {
  getMySessions,
  getSessionbyId,
  getWebinar,
} from "../../api/Sessions/actions";
import Navbar from "../../components/Navbar";
import { useState } from "react";
import Modal from "react-modal";

function SessionDetails() {
  const { uuid } = useParams();
  const { mysession, loading, userSessions, webinar } = useSelector(
    (state) => state.session
  );
  useEffect(() => {
    if (mysession?.webinar_id) {
      dispatch(getWebinar(mysession?.webinar_id));
    }
  }, [mysession]);
  console.log(webinar, "webinar");

  const KJUR = require("jsrsasign");

  function getSignature(key, secret, meetingNumber, role) {
    const iat = Math.round(new Date().getTime() / 1000) - 30;
    const exp = iat + 60 * 60 * 2;
    const oHeader = { alg: "HS256", typ: "JWT" };

    const oPayload = {
      sdkKey: key,
      appKey: key,
      mn: meetingNumber,
      role: role,
      iat: iat,
      exp: exp,
      tokenExp: exp,
    };

    const sHeader = JSON.stringify(oHeader);
    const sPayload = JSON.stringify(oPayload);
    const sdkJWT = KJUR.jws.JWS.sign("HS256", sHeader, sPayload, secret);
    console.log(sdkJWT, "sig");
    myFunction(sdkJWT);
  }
  const myFunction = async (signature) => {
    // run asynchronous tasks here
    const { ZoomMtg } = await import("@zoomus/websdk");
    ZoomMtg.setZoomJSLib("https://source.zoom.us/2.17.0/lib", "/av");
    ZoomMtg.preLoadWasm();

    ZoomMtg.prepareWebSDK();
    // loads language files, also passes any error messages to the ui
    ZoomMtg.i18n.load("en-US");
    ZoomMtg.i18n.reload("en-US");
    document.getElementById("zmmtg-root").style.display = "block";
    let text = `${window?.location?.href}`;
    let template_url = text.substr(0, text.lastIndexOf("/"));
    ZoomMtg.init({
      leaveUrl: template_url,
      success: (success) => {
        console.log(success);
        ZoomMtg.join({
          signature: signature,
          sdkKey: webinar.sdkKey,
          meetingNumber: mysession.webinar_id,
          passWord: "0000",
          userName: webinar.userName,
          userEmail: webinar.userEmail,
          tk: webinar.registrantToken,
          zak: webinar.zakToken,
          success: (success) => {
            console.log(success, "----------Joined----------");
          },
          error: (error) => {
            console.log(error);
          },
        });
      },
      error: (error) => {
        console.log(error);
      },
    });
  };
  useEffect(() => {
    if (webinar?.sdkKey && mysession) {
      console.log(webinar.signature, mysession.webinar_id, "webinar");

      getSignature(
        "l3Pe8PkiShWf2OuDfS4sHQ",
        "WJENC8ZKr7rAfZY8yYL4f1RUPeJS4KJm",
        mysession.webinar_id,
        0
      );
    }
  }, [webinar, mysession]);
  const dispatch = useDispatch();
  const [myid, setMyId] = useState(-1);
  const { record, agenda } = useSelector((state) => state.event);
  const { user, userTicket } = useSelector((state) => state.user);
  const [speaker, setSpeaker] = useState("off");
  const [signModal, setSignModal] = useState(false);
  const navigate = useNavigate();

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      width: "23.188rem",
      height: "15.125rem",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };
  useEffect(() => {
    if (record.id) {
      let token = localStorage.getItem("user-token");

      if (!token) {
        setSignModal(true);
      }
      if (token) {
        dispatch(getMySessions({ event_id: record?.id }));
      }
    }
  }, [record]);
  useEffect(() => {
    if (uuid) {
      dispatch(getSessionbyId({ session_id: uuid }));
    }
  }, [uuid]);
  useEffect(() => {
    if (userSessions.length > 0) {
      const searchIndex = userSessions.findIndex((car) => car.id == uuid);
      setMyId(searchIndex);
    }
  }, [userSessions]);
  useEffect(() => {
    if (user?.id && mysession?.speakers?.length > 0) {
      console.log(user.email, mysession.speakers);
      const sid = mysession.speakers.findIndex(
        (obj) => obj.email == user.email?.toLowerCase()
      );

      if (sid != -1) {
        setSpeaker("on");
      }
    }
  }, [mysession]);
  return (
    <div>
      {!loading && mysession?.id && (
        <>
          <Navbar></Navbar>
          <div class="header-section">
            <img
              src={`https://frontiers.o-projects.org/storage/${mysession.cover_image}`}
              class="d-block w-100 head-slide-img"
            />
            <div class="half-circle">
              <img
                src="../../assets/images/semi-circle.png"
                class="semi-circle"
              />
              <img
                src={`https://frontiers.o-projects.org/storage/${mysession.logo}`}
                class="circle-logo"
              />
              <div class="overlay-info overlay-info2">
                <p class="light-blue-text regular-font mb-1 page-title3">
                  {mysession.title}
                </p>

                <p
                  class="white-text regular-font page-info2"
                  dangerouslySetInnerHTML={{
                    __html: mysession.description,
                  }}
                ></p>
                <p class="white-text regular-font pt-3">
                  <i class="fa-solid fa-clock me-2"></i>
                  {mysession.start_time}-{mysession.end_time}
                </p>
              </div>
            </div>
          </div>
          {mysession.room?.id && myid != -1 && (
            <div className="session">
              <iframe
                style={{ height: "600px" }}
                src={`${mysession.room.roomUrl}?background=off&screenshare=${speaker}`}
                allow="camera; microphone; fullscreen; speaker; display-capture; autoplay"
              ></iframe>
            </div>
          )}
          {mysession.video_link != "null" && (
            <div class="container">
              <div class="row">
                <div class="col-md-10 offset-md-1 text-center">
                  <div class="video-section">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: mysession?.video_link,
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="section-top-space3">
            <div class="container">
              <div class="row">
                <div class="col-md-10 offset-md-1 text-center">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      margin: "2rem 0",
                    }}
                  >
                    <img
                      src={`https://frontiers.o-projects.org/storage/${mysession.details_image}`}
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>{" "}
          <div id="zmmtg-root">
            <div data-reactroot="" class="meeting-app"></div>{" "}
          </div>
          <div class="ReactModalPortal"></div>
          {mysession.speakers.length > 0 && (
            <div class="section-yaxis-space" id="SpeakersSection">
              <div class="container">
                <div class="row mb-4">
                  <div class="col-md-12">
                    <p class="dark-blue-text bold-font page-title1 lined-title3">
                      List of Speakers
                    </p>
                  </div>
                </div>
              </div>

              <div class="cards-brands-slider no-margin-slider">
                {mysession.speakers.map((speaker, id) => (
                  <div class="item" key={id}>
                    <div class="card page-card up-view">
                      <div class="card-body">
                        <img
                          src={`https://frontiers.o-projects.org/storage/${speaker.avatar}`}
                          class="speaker-image"
                          alt="Development Icon"
                        />
                        <div class="card-div">
                          <p class="card-title regular-font page-title3 mb-0">
                            {speaker.name}
                          </p>
                          <p class="card-info bold-font mb-0 page-title4">
                            {speaker.fieldofwork}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
          {/* {mysession?.attendance.length > 0 && (
            <div class="section-yaxis-space attendees-section">
              <div class="container">
                <div class="row mb-4">
                  <div class="col-md-12 text-center">
                    <p class="light-blue-text bold-font page-title1 lined-title4">
                      List of Attendees
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-10 offset-lg-1 col-md-10 offset-md-1 text-center">
                    <div class="list-section">
                      <div class="list-container">
                        <ul class="page-list">
                          {mysession.attendance?.map((session, index) => (
                            <li>
                              <div class="d-flex justify-content-between mb-2">
                                <p class="regular-font white-text page-info3 mb-0">
                                  {" "}
                                  {session?.name}
                                </p>
                               
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )} */}
          <footer>
            <div class="footer-section">
              <div class="container">
                <div class="row mb-4">
                  <div class="col-md-12 text-center">
                    <a href="index.html">
                      <img
                        src="../../assets/images/frontiers-logo.png"
                        class="frontiers-logo"
                        alt="Frontiers Logo"
                      />
                    </a>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4 offset-md-4 text-center">
                    {record?.facebook_link != "#" && (
                      <a href={record.facebook_link} className="me-5 dark-text">
                        <i className="fa-brands fa-facebook-square page-info1"></i>
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div class="footer-line2">
              <div class="container">
                <div class="row">
                  <div class="col-md-12 text-center">
                    <p class="mb-0 white-text">
                      © Frontiers. All rights reserved 2022 - Made By O-Projects
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </>
      )}
    </div>
  );
}

export default SessionDetails;
