import React, { useEffect, useState } from "react";
import { Route, Router, useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getSelectedTicket } from "../../api/Tickets/actions";
import group31 from "../../assets/images/Group931.jpg";
import { getEventsDetails } from "../../api/Events/actions";
import Modal from "react-modal";

const paymntinfo = [];
function PurchaseTicket() {
  const dispatch = useDispatch();
  const { isAuth } = useSelector((state) => state.user);
  const { ticketTypes } = useSelector((state) => state.event);
  const { selectedTicket } = useSelector((state) => state.ticket);
  const [signModal, setSignModal] = useState(false);
  const [payment, setPayment] = useState(paymntinfo);
  const [redeem, setRedem] = useState(false);
  const navigate = useNavigate();
  const [ticket, setTicket] = useState([]);
  const router = useParams();
  const [error, setError] = useState(false);
  let token1 = localStorage.getItem("user-info");

  useEffect(() => {
    if (router.id) {
      dispatch(getEventsDetails({ alias: `${router.id}` }));
    }
  }, []);
  console.log(payment, "paay");
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      width: "23.188rem",
      height: "15.125rem",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };
  useEffect(() => {}, [isAuth]);
  useEffect(() => {
    if (ticketTypes.length > 0) {
      for (let i = 0; i < ticketTypes.length; i++) {
        if (ticketTypes[i].price || ticketTypes[i].price !== 0) {
          setRedem(true);
          return;
        } else {
          setRedem(false);
        }
      }
    }
  }, [ticketTypes]);
  const selectTicket = (ticket) => {
    if (!token1) {
      setSignModal(true);
    } else {
      if (payment.length == 0) {
        setError(true);
        return;
      }

      dispatch(
        getSelectedTicket({
          body: ticket,
          nav: navigate,
          alias: router?.id,
          payment: payment,
        })
      );
      console.log(selectedTicket);
    }
  };
  console.log(ticketTypes, "tickettypes");
  return (
    ticketTypes?.length > 0 && (
      <div>
        <Modal
          isOpen={signModal}
          onRequestClose={() => {
            setSignModal(false);
          }}
          style={customStyles}
        >
          <div>
            <div
              className="flex-center"
              style={{
                color: "#2B6EC8",
                fontSize: "1rem",
                fontWeight: "bold",
              }}
            >
              Please sign in or register to get your invitation
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "50px",
              }}
            >
              <div
                class="form-btn page-info2 bold-font"
                //disabled={error}
                onClick={() => navigate("/" + router?.id + "/register")}
              >
                Register
              </div>
            </div>
          </div>
        </Modal>
        <main>
          <div class="container-fluid">
            <div class="row align-items-center">
              <div class="col-md-6 p-0">
                <div class="position-relative blue-header">
                  <img src={group31} class="side-img" />
                  {redeem ? (
                    <div class="side-vertical-tabs">
                      <p class="side-menu-title text-center text-md-end pe-4">
                        Reservation
                      </p>
                    </div>
                  ) : (
                    <div class="side-vertical-tabs">
                      <p class="side-menu-title text-center text-md-end pe-4">
                        Get Your Invitation
                      </p>
                    </div>
                  )}
                </div>
              </div>
              <div class="col-md-6 half-side-page">
                <div class="px-5">
                  <p class="page-title5 blue-text bold-font mb-5">
                    Choose Invitation Type
                  </p>
                  {ticketTypes?.map((type, index) => (
                    <div>
                      {type.soldquantity != type.quantity && (
                        <div
                          class="ticket-card mb-3"
                          key={index}
                          style={
                            type.id == payment.ticketid
                              ? { backgroundColor: "#00D7E1", color: "#222222" }
                              : {}
                          }
                        >
                          <a>
                            <div class="d-flex justify-content-between">
                              <p class="black-text bold-font page-info1">
                                {type.name}
                              </p>

                              <p class="blue-text bold-font page-info1 price-text">
                                {type.price ? type.price : "Free"}
                              </p>
                            </div>
                            <div class="d-flex justify-content-between">
                              <p class="black-text regular-font page-info4">
                                {type.description}
                              </p>
                              <p class=" d-flex blue-text bold-font page-info1 price-text">
                                <img
                                  className="plus-icon"
                                  src="../assets/images/minus-circle-solid.svg"
                                  onClick={() => {
                                    if (!token1) {
                                      setSignModal(true);
                                    }
                                    console.log(payment);
                                    const index = payment.findIndex(
                                      (element) => element.ticketid === type.id
                                    );
                                    if (payment[index]?.quantity > 0) {
                                      let pay = [...payment];
                                      pay[index].ticketid = type.id;
                                      pay[index].name = type.name;
                                      pay[index].quantity =
                                        pay[index].quantity - 1;
                                      if (pay[index].quantity == 0) {
                                        pay.splice(index, 1);
                                      }
                                      setPayment(pay);
                                      setTicket(type);
                                    }
                                  }}
                                  style={{
                                    width: "1rem",
                                    top: "-0.5rem",
                                    position: "relative",
                                  }}
                                ></img>
                                <p
                                  style={
                                    error && !payment.ticketid
                                      ? {
                                          marginLeft: "0.7rem",
                                          marginRight: "0.7rem",
                                          color: "red",
                                        }
                                      : {
                                          marginLeft: "0.7rem",
                                          marginRight: "0.7rem",
                                        }
                                  }
                                >
                                  {payment[
                                    payment.findIndex(
                                      (element) => element.ticketid === type.id
                                    )
                                  ]?.ticketid == type.id
                                    ? payment[
                                        payment.findIndex(
                                          (element) =>
                                            element.ticketid === type.id
                                        )
                                      ]?.quantity
                                    : 0}
                                </p>
                                <img
                                  onClick={() => {
                                    if (!token1) {
                                      setSignModal(true);
                                    }
                                    const index = payment.findIndex(
                                      (element) => element.ticketid === type.id
                                    );
                                    if (index == -1) {
                                      let pay = [...payment];
                                      pay.push({
                                        quantity: 1,
                                        ticketid: type.id,
                                        name: type.name,
                                      });
                                      setPayment(pay);
                                    } else if (
                                      type.max > payment[index].quantity
                                    ) {
                                      let pay = [...payment];
                                      pay[index].ticketid = type.id;
                                      pay[index].name = type.name;
                                      pay[index].quantity =
                                        pay[index].quantity + 1;
                                      setPayment(pay);
                                    }
                                    setTicket(type);
                                  }}
                                  src="../assets/images/plus-circle-solid.svg"
                                  style={{
                                    width: "1rem",
                                    top: "-0.5rem",
                                    position: "relative",
                                  }}
                                  className="plus-icon"
                                ></img>
                              </p>
                            </div>
                          </a>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
                <div class="row mt-5" style={{ justifyContent: "center" }}>
                  <div class="col-md-6" className="submit-ticket-form">
                    <button
                      class="form-btn page-info2 bold-font"
                      //disabled={error}
                      onClick={() => selectTicket(payment)}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    )
  );
}

export default PurchaseTicket;
