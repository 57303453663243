import { createReducer } from "@reduxjs/toolkit";
import {
  getCountries,
  getSelectedTicket,
  getTicketCode,
  purchaseTicket,
} from "./actions";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

const initialState = {
  countries: [],
  selectedTicket: [
    {
      id: null,
      name: "",
      name_ar: "",
      description: "",
      description_ar: "",
      type: null,
      servicefees_type: null,
      price: null,
      price_override: null,
      quantity: null,
      soldquantity: null,
      needapproval: 0,
      isprivate: 0,
      min: 1,
      max: 1,
      startduration: "",
      endduration: "",
      paymentdeadlinetype: null,
      paymentdeadline_days: null,
      infocollect_type: 1,
      event_id: null,
      fallback_ticket_id: null,
      created_at: "",
      updated_at: "",
    },
  ],
  ticketTypes: [],
  paymentinfo: [],
  error: false,
  isFound: false,
  type: null,
  Message: "",
};

export const ticketReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(purchaseTicket.pending, (state) => {
      state.error = true;
    })

    .addCase(purchaseTicket.rejected, (state, { payload }) => {
      console.log("hi");
      state.error = false;
    })
    .addCase(getSelectedTicket.fulfilled, (state, { payload }) => {
      console.log(payload, "payload");
      //state.selectedTicket = payload[0];
      //let pay=0

      //state.selectedTicket.quantity=pay
      state.paymentinfo = payload[1];
      state.error = false;
    })
    .addCase(getCountries.fulfilled, (state, { payload }) => {
      console.log(payload, "payload");
      //state.selectedTicket = payload[0];
      //let pay=0

      //state.selectedTicket.quantity=pay
      state.countries = payload;
    })
    .addCase(getTicketCode.pending, (state) => {
      state.error = true;
    })

    .addCase(getTicketCode.rejected, (state, { payload }) => {
      console.log("hi");
      state.error = false;
    })
    .addCase(getTicketCode.fulfilled, (state, { payload }) => {
      if (!payload[0].isFound) {
        state.Message = "PromoCode is not valid";
        state.isFound = false;
      } else {
        state.isFound = true;
        state.ticketTypes = payload[0].ticketTypes;
        state.selectedTicket = [payload[0].ticketTypes[0]];
        state.selectedTicket[0].passcode = payload[1];
        console.log(state.selectedTicket, payload, "payy");
        let quan = {
          quantity: 1,
          ticketid: payload[0].ticketTypes[0].id,
          name: payload[0].ticketTypes[0].name,
        };
        console.log(quan, "payy");
        state.paymentinfo = [quan];
        state.error = false;
      }
    });
});

export default ticketReducer;
